import { gql } from "@apollo/client";

export const AUTHENTICATE_MAIL = gql`
  mutation authenticateMail($input: MailAuthInput!) {
    authenticateMail(input: $input) {
      flag
    }
  }
`;

export const AUTHENTICATE_GMAIL_OAUTH = gql`
  mutation authenticateOAuthGMail($input: GMailOAuthInput!) {
    authenticateOAuthGMail(input: $input) {
      flag
    }
  }
`;
