import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { authReducer } from "./auth.slice";
import { menuReducer } from "./menu.slice";
import { dateReducer } from "./date.slice";
import { responsiveReducer } from "./responsive.slice";
import { subscriptionReducer } from "./subscription.slice";
import { cardInfoReducer } from "./card.slice";
import { paymentReducer, clearSubscription } from "./payment.slice";

import storage from "./storage";
import { checkReducer } from "./check.slice";
import { punchReducer } from "./punch.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  date: dateReducer,
  responsive: responsiveReducer,
  cardInfo: cardInfoReducer,
  payment: paymentReducer,
  check: checkReducer,
  punch: punchReducer,
});

const persistConfig = {
  timeout: 0,
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

// Add middleware to clear the subscription data after 30 days
const state = store.getState();
const date = state.payment.date;
const subscription = state.payment.subscription;

if (date) {
  const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000;
  const oneYearInMillis = 365 * 24 * 60 * 60 * 1000;
  const currentTime = new Date().getTime();

  if (subscription === "monthly" && currentTime - date >= thirtyDaysInMillis) {
    store.dispatch(clearSubscription());
  }

  if (subscription === "yearly" && currentTime - date >= oneYearInMillis) {
    store.dispatch(clearSubscription()); // You would need to define this action
  }
}

export default store;
