import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const { authenticated } = useSelector((state) => state.auth);
  let location = useLocation();
  let from = location?.state?.from;

  if (process.env.REACT_APP_NODE_ENV !== "development") {
    window.location = process.env.REACT_APP_ZWILT_APP;
    return <></>;
  }

  if (authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={
          from?.pathname && from?.search
            ? `${from?.pathname}${from?.search}`
            : from?.pathname && !from?.search
            ? `${from?.pathname}`
            : "/"
        }
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

export default AuthGuard;
