import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { IS_ON_FREE_TRIAL } from "../graphql/queries/freeTrialQueries";
import { FETCH_USER } from "../graphql/queries/user";
import { setUser } from "../redux/auth.slice";

const RequireAuth = ({ children }) => {
  const { authenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const { data } = useQuery(IS_ON_FREE_TRIAL, {
  //   variables: { userId },
  //   skip: !authenticated, // Skip the query if the user is not authenticated
  // });

  // fetch current user
  const { loading, error, data } = useQuery(FETCH_USER);

  let location = useLocation();

  useEffect(() => {
    if (data?.me && data?.me?._id !== user?._id) {
      dispatch(setUser(data.me));
    }
  }, [data]);

  if (!authenticated && !loading) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    if (process.env.REACT_APP_NODE_ENV !== "development") {
      window.location = process.env.REACT_APP_ZWILT_APP;
      return <></>;
    }

    return (
      <Navigate
        to={location?.search ? `/login${location?.search}` : "/login"}
        state={{ from: location }}
        replace
      />
    );
  }

  if (loading) return <></>;
  // if (data && !data.isOnFreeTrial) {
  //   return <Navigate to="/pay" replace />;
  // }

  return <>{children}</>;
};

export default RequireAuth;
