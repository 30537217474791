import { Box, IconButton } from "@mui/material";

import Table from "../Table";

import { useEffect, useState } from "react";
import { TitleText } from "../CampaignList/styled";

import { useQuery, useMutation } from "@apollo/client";

import { useSnackbar } from "notistack";
import { CreateButton } from "../SalesList/styled";

import AddModal from "./addModal";
import { FETCH_VERIFIED_EMAILS } from "../../graphql/queries/sendgrid";
import EllipsisLinkBrand from "../EllipsisLink/EllipsisLink";

export default ({ isMobile, br_id }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    data,
    loading: loadingData,
    refetch,
  } = useQuery(FETCH_VERIFIED_EMAILS, { variables: { br_id } });

  const reload = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  const tableHeadCells = [
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email Address",
      width: "20%",
    },
    {
      id: "e_mode",
      numeric: false,
      disablePadding: true,
      label: "Mode",
      width: "20%",
    },
  ];

  const getMode = (val) => {
    switch (String(val).trim()) {
      case "imap":
        return "IMAP/SMTP";
      case "gmail1":
        return "GMail App Password";
      case "gmail2":
        return "GMail OAuth";
      default:
        return "";
    }
  };

  const getRows = () => {
    let rows = (
      data?.fetchVerifiedEmails instanceof Array
        ? data?.fetchVerifiedEmails
        : []
    ).map((row) => {
      let obj = {
        ...row,
        e_mode: getMode(row?.mode),
      };

      return obj;
    });

    return rows;
  };
  return (
    <Box sx={{ minHeight: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TitleText sx={{ my: 3 }}>Verified Email Addresses</TitleText>
        <Box>
          {/* <CreateButton
            hasBorder
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Add New
          </CreateButton> */}
          <EllipsisLinkBrand
            title="Add New"
            style={{ width: "auto" }}
            styleText={{ color: "#1a1b24" }}
            content={[
              {
                title: "IMAP/SMTP",
                link: () => {
                  setOpenModal("imap");
                },
              },
              {
                title: "GMAIL - App Password",
                link: () => {
                  setOpenModal("gmail1");
                },
              },
              {
                title: "GMAIL - OAuth",
                link: () => {
                  setOpenModal("gmail2");
                },
              },
            ]}
          />
        </Box>
      </Box>

      {loadingData && loading ? (
        "Loading ..."
      ) : (
        <Table
          headCells={tableHeadCells}
          // rowClick={rowClick}
          rows={getRows()}
          stylehead
        />
      )}
      <AddModal
        open={openModal}
        br_id={br_id}
        setOpen={setOpenModal}
        refetch={reload}
      />
    </Box>
  );
};
