import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import ColorButton from "../../components/ColorButton";
import { SubTitle, Title, ModalContent } from "./styled";
import SendMailDialog from "./SendMailDialog";
import Dialog from "../../components/Dialog";
import { useMutation } from "@apollo/client";
import {
  SAVE_TEMPLATE_MUTATION,
  UPDATE_TEMPLATE_MUTATION,
} from "../../graphql/mutations/saveTemplateMutation";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

//Comment for Joshua
const CustomModal = ({
  leadsFields,
  isOpen,
  selectedHTMLContent,
  onRequestClose,
  onSave,
  editMode,
  newsletterTitle,
  newsletterFolder,
  displayMode,
  parsedHTML,
  savedTemplate,
  loadedDesignHTML,
  loadedHTML,
  setPreviewImage,
}) => {
  const menu = useSelector((state) => state.menu);
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);
  const editorRef = useRef(null);
  const [designHTML, setDesignHTML] = useState("");
  const [sendMailDialogOpen, setSendMailDialogOpen] = useState(false);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);
  const designRef = useRef("");
  const [saveTemplateMutation] = useMutation(SAVE_TEMPLATE_MUTATION);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [updateTemplateMutation] = useMutation(UPDATE_TEMPLATE_MUTATION);

  // console.log({ leadsFields });
  const handleCloseEditor = () => {
    setConfirmCloseDialogOpen(true);
  };

  const handleBack = () => {
    setConfirmCloseDialogOpen(true); // Open the confirm close dialog when going back
  };

  const confirmCloseEditor = () => {
    setConfirmCloseDialogOpen(false);
    handleUpdateTemplate(); // Call the function to update the template before closing
    onRequestClose();
    setIsEditorLoaded(false);
  };

  const discardDesign = () => {
    setConfirmCloseDialogOpen(false);
    onRequestClose();
    setIsEditorLoaded(false);
  };

  const openSendMailDialog = () => {
    setSendMailDialogOpen(true);
  };

  const closeSendMailDialog = () => {
    setSendMailDialogOpen(false);
  };

  const handleSaveTemplate = () => {
    // console.log("Design to be saved:", selectedHTMLContent);

    window.unlayer.saveDesign(function (design) {
      const lastUpdate = new Date().toString();
      // console.log("this is design from",design)
      window.unlayer.exportHtml(function (data) {
        const html = data.html;
        // console.log("HTML to be saved:", html);

        const imageData = extractPreviewImageFromHTML(html);
        // console.log("Image data captured in Modal.js:", imageData);

        const event = new CustomEvent("imageCaptured", { detail: imageData });
        window.dispatchEvent(event);

        saveTemplateMutation({
          variables: {
            input: {
              title: newsletterTitle,
              layoutType: displayMode,
              designBody: JSON.stringify(design),
              designHTML: html,
              imageData: imageData,
              brand: menu?.id,
            },
          },
        })
          .then((response) => {
            // console.log("Template saved successfully:", response);
            let obj =
              response?.data?.saveTemplate instanceof Object
                ? response?.data?.saveTemplate
                : {};
            // console.log("Saved Template:", response.data.saveTemplate);
            onSave({
              id: obj?.id,
              title: newsletterTitle,
              folder: newsletterFolder,
              displayMode,
              lastUpdate,
              designHTML: html,
              imageData: imageData,
            });
          })
          .catch((error) => {
            console.error("Error saving template:", error);
          });
      });
    });
  };

  const extractPreviewImageFromHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const imgElement = tempDiv.querySelector("img");
    if (imgElement) {
      return imgElement.src;
    } else {
      return null;
    }
  };

  const handleUpdateTemplate = () => {
    window.unlayer.saveDesign(function (design) {
      const lastUpdate = new Date().toString();
      // console.log("Design to be updated:", design);

      window.unlayer.exportHtml(function (data) {
        const html = data.html;
        // console.log("HTML to be updated:", html);

        const imageData = extractPreviewImageFromHTML(html);
        // console.log("Image data captured in Modal.js:", imageData);

        onSave({
          id: savedTemplate.id,
          title: newsletterTitle,
          folder: newsletterFolder,
          displayMode,
          lastUpdate,
          designHTML: html,
          imageData: imageData,
        });

        const event = new CustomEvent("imageCaptured", { detail: imageData });
        window.dispatchEvent(event);

        updateTemplateMutation({
          variables: {
            input: {
              id: savedTemplate.id,
              title: newsletterTitle,
              layoutType: displayMode,
              designBody: JSON.stringify(design),
              designHTML: html,
              imageData: imageData,
            },
          },
        })
          .then((response) => {
            // console.log("Template updated successfully:", response);
            // console.log("Updated Template:", response.data.updateTemplate);
          })
          .catch((error) => {
            // console.error("Error updating template:", error);
          });
      });
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (isOpen && !isEditorLoaded) {
      const script = document.createElement("script");
      script.src = "https://editor.unlayer.com/embed.js";
      script.async = true;
      script.onload = () => {
        setIsEditorLoaded(true);
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen, isEditorLoaded]);

  useEffect(() => {
    if (isOpen && isEditorLoaded && editorRef.current) {
      const windowHeight = window.innerHeight;
      const editorHeight = windowHeight * 0.8;

      // console.log(
      //   "loadedDesignHTML inside useEffect of Modal:",
      //   loadedDesignHTML
      // );

      window.unlayer.init({
        appearance: {
          theme: "dark",
        },
        loader: {
          html: '<div class="custom-spinner"></div>',
          css: ".custom-spinner { color: red; }",
        },

        tools: {
          "custom#my_tool": {
            data: {
              name: "John Doe",
              age: "27",
              photo: "http://via.placeholder.com/350x150",
            },
          },
        },
        id: "editor",
        projectId: 229117,
        displayMode: "email",
        width: "100%",
        height: `${editorHeight}px`,
        minHeight: "80vh",
        mergeTags: leadsFields,
      });

      // if (editMode) {
      //   window.unlayer.loadDesign(loadedDesignHTML);
      // } else if (selectedHTMLContent) {
      //   // window.unlayer.loadDesign();
      //   document.getElementById("editor").innerHTML = selectedHTMLContent;
      // } else {
      //   window.unlayer.loadDesign(loadedDesignHTML);
      // }

      if (editMode) {
        window.unlayer.loadDesign(loadedDesignHTML);
      } else if (selectedHTMLContent) {
      }

      window.unlayer.addEventListener("design:updated", function (updates) {
        window.unlayer.exportHtml(function (data) {
          const json = data.design;
          const html = data.html;

          if (loadedDesignHTML) {
            setDesignHTML(loadedDesignHTML);
          } else {
            setDesignHTML(html);
          }
          designRef.current = html;
          // console.log(
          //   "loadedDesignHTML inside useEffect of Modal:",
          //   loadedDesignHTML
          // );
          // console.log("html:", html);
        });
      });
    }
  }, [isOpen, isEditorLoaded, loadedDesignHTML]);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={savedTemplate ? "Edit Template" : "Create New Template"}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "95%",
          height: "95%",
          border: "1px solid #ccc",
          borderRadius: "8px",
          overflow: "hidden",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
        }}
      >
        <ColorButton
          title="Send As Email..."
          background="black"
          textColor="#fff"
          buttonTextStyle={{ color: "white" }}
          hoverColor="black"
          hoverBackgroundColor="#464646"
          borderWidth="2px"
          activeBorderColor="grey"
          activeBgColor="grey"
          hoverBorder="grey"
          onClick={openSendMailDialog}
          style={{ marginRight: "12px" }}
        />
        <ColorButton
          title={editMode ? "Update This Design" : "Save This Design"} // Change button text based on edit mode
          background="black"
          textColor="#fff"
          buttonTextStyle={{ color: "white" }}
          hoverColor="black"
          hoverBackgroundColor="#464646"
          borderWidth="2px"
          activeBorderColor="grey"
          activeBgColor="grey"
          hoverBorder="grey"
          onClick={editMode ? handleUpdateTemplate : handleSaveTemplate} // Call different functions based on edit mode
          style={{ marginRight: "12px" }}
        />
        <ColorButton
          title="Close Editor"
          background="#D6DAE0"
          textColor="#000"
          hoverColor="black"
          hoverBackgroundColor="silver"
          borderWidth="2px"
          activeBorderColor="grey"
          activeBgColor="grey"
          hoverBorder="grey"
          onClick={handleCloseEditor}
        />
      </div>

      <div
        style={{
          position: "absolute",
          top: "-35px",
          left: "20px",
          textAlign: "left",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            marginTop: "45px",
            paddingRight: "15px",
            marginRight: "5px",
            backgroundColor: "white",
            paddingBottom: "5px",
            paddingLeft: "5px",
            marginLeft: "-5px",
          }}
          onClick={handleBack}
        >
          <ArrowBackIcon />
        </div>
        <Title
          style={{
            backgroundColor: "white",
            paddingBottom: "5px",
            paddingRight: "10px",
            paddingLeft: "10px",
            marginLeft: "-5px",
          }}
        >
          {newsletterTitle}
        </Title>
        <SubTitle
          style={{
            backgroundColor: "white",
            paddingBottom: "5px",
            paddingRight: "20px",
            paddingLeft: "20px",
            marginTop: "51px",
            color: "silver",
          }}
        >
          {newsletterFolder} . {displayMode}
        </SubTitle>
      </div>

      <div
        style={{ width: "100%", height: "100%" }}
        id="editor"
        ref={editorRef}
      ></div>

      {confirmCloseDialogOpen && (
        <Dialog
          open={confirmCloseDialogOpen}
          onClose={() => setConfirmCloseDialogOpen(false)}
          title="Confirm Close Editor"
          setOpen={setConfirmCloseDialogOpen}
        >
          <div style={{ textAlign: "center", padding: "15px" }}>
            {/* Close icon */}
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setConfirmCloseDialogOpen(false)}
            >
              <CloseIcon />
            </div>
            Save your design before closing?
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ColorButton
                title="Yes, Save and Close"
                background="black"
                buttonTextStyle={{ color: "white" }}
                hoverColor="black"
                hoverBackgroundColor="grey"
                borderWidth="2px"
                activeBorderColor="grey"
                activeBgColor="grey"
                hoverBorder="grey"
                onClick={confirmCloseEditor}
                style={{ marginRight: "10px" }}
              />
              <ColorButton
                title="Discard"
                background="brown"
                buttonTextStyle={{ color: "white" }}
                hoverColor="black"
                hoverBackgroundColor="#d32f2f"
                borderWidth="2px"
                activeBorderColor="grey"
                activeBgColor="grey"
                hoverBorder="grey"
                onClick={discardDesign}
              />
            </div>
          </div>
        </Dialog>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <SendMailDialog
        isOpen={sendMailDialogOpen}
        onClose={closeSendMailDialog}
        onSave={onSave}
        initialRecipientEmail={""}
        newsletterTitle={newsletterTitle}
        editorRef={editorRef}
        designHTML={designHTML}
        loadedHTML={loadedHTML}
        loadedDesignHTML={loadedDesignHTML}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarOpen={setSnackbarOpen}
      />
    </Modal>
  );
};

export default CustomModal;
