import { gql } from "@apollo/client";
import { USER } from "../fragments/user";

export const FETCH_USER = gql`
  ${USER}
  query {
    me {
      ...UserFields
    }
  }
`;
export const GOOGLE_QUERY = gql`
  query {
    google {
      _id
      name
      email
    }
  }
`;

//returns a single user
export const GET_USER_BY_ID = gql`
  ${USER}
  query findUserById($input: FindUserByIdInput!) {
    findUserById(input: $input) {
      ...UserFields
      thumbnail
    }
  }
`;

const GET_USER_SUBSCRIPTION_STATUS = gql`
  query getUserSubscriptionStatus($userId: ID!) {
    user(id: $userId) {
      subscriptionStatus
    }
  }
`;
