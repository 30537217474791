import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { InputAdornment } from "@mui/material";
import { getCountryFlag, getCountryItems } from "../../utils/countryPix";
import countryCodes from "../../utils/countryCodes";

export default function CountrySelect({
  phoneCode,
  setPhoneCode,
  getDefaultValues,
  orgValue,
}) {
  console.log(phoneCode, "phn Code");
  const [imgSelect, setImgSelect] = React.useState(null);

  React.useEffect(() => {
    setImgSelect(getCountryFlag(phoneCode));
    // console.log(phoneCode, "Codeo");
  }, [phoneCode]);

  return (
    <Autocomplete
      sx={{
        ".MuiInputBase-root": {
          padding: "0 !important",
          paddingLeft: "5px !important",
          width: "100px",
          cursor: "pointer",
        },
      }}
      options={countryCodes}
      popupIcon={null}
      PopperComponent={null}
      clearIcon={null}
      autoHighlight
      ListboxComponent={(props) => (
        <Box
          {...props}
          sx={{
            boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
            minWidth: 0,
            fontFamily: "Dm Sans",
            maxHeight: "200px",
            border: "0.5px solid  #ECECF2",
            background: "#FDFDFD",
            borderRadius: "6px", // Example styles
            "&::-webkit-scrollbar": {
              width: "5px", // Adjust the width of the scrollbar as needed
            },
            padding: "0 !important",
          }}
        />
      )}
      //   value={}
      value={getDefaultValues}
      getOptionLabel={(option) => option?.code}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            backgroundColor:
              phoneCode === option?.code
                ? "rgba(25, 118, 210, 0.08)"
                : undefined,
            fontFamily: "Dm Sans",
          }}
          //   sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {getCountryItems(option?.code)}
        </Box>
      )}
      onChange={(event, newValue) => {
        // alert(newValue);
        // console.log(newValue);
        setPhoneCode(newValue?.code);
      }}
      renderInput={(params) => {
        if (params?.InputProps) {
          params.InputProps.startAdornment = imgSelect ? (
            <img src={imgSelect} style={{ width: 24, height: 24 }} />
          ) : (
            <div style={{ width: "24px" }}>&nbsp;</div>
          );
        }
        if (params?.inputProps)
          params.inputProps.sx = {
            textAlign: "center",
          };
        // console.log(params, "Params");

        return (
          <div
            style={{
              display: "flex",
              height: "24px",
              alignItems: "center",
              cursor: "pointer",
              fontFamily: "Dm Sans",
            }}
          >
            <TextField
              {...params}
              //   label="Choose a country"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          </div>
        );
      }}
    />
  );
}
