import { gql } from "@apollo/client";

export const FETCH_VERIFIED_EMAILS = gql`
  query fetchVerifiedEmails($br_id: String!) {
    fetchVerifiedEmails(br_id: $br_id) {
      email
      createdAt
      mode
    }
  }
`;
