export default () => {
  //   const dispatch = useDispatch();
  //   // Function to set a cookie
  function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Setting expiration
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/; secure; SameSite=None; domain=.recrowdly.com`;
  }

  // Example: Set a cookie named "userToken" with a value

  const getPunchID = () => {
    window.addEventListener("message", (event) => {
      if (event.origin === process.env.REACT_ZWILT_APP) {
        // Use the correct origin
        // console.log("Message from parent:", event.data);
        // alert(JSON.stringify(event.data));
        try {
          let { token, punchId } = JSON.parse(event.data);
          alert(event.data);
          console.log("Message", event.data);
          setCookie("punch_token", token, 7);
          setCookie("punch_id", punchId, 7);
        } catch (e) {}
        // setCookie("punch_id2_", event.data, 7);
        // localStorage.setItem("punch_id3_", event.data);
        // Respond to the parent
        event.source.postMessage("Hello from iframe StagingAOj", event.origin);
      }
    });
  };

  //   useEffect(() => {
  getPunchID();
  //   }, [dispatch]);

  return <>Save Punch ID</>;
};
