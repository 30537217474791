import {
  Modal,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Switch,
  IconButton,
} from "@mui/material";
import { ModalContainer, StyledAuthButton } from "../CampaignList/styled";

import MailList from "../SalesList/mailList";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import { useSnackbar } from "notistack";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import {
  AUTHENTICATE_MAIL,
  AUTHENTICATE_GMAIL_OAUTH,
} from "../../graphql/mutations/otherMail";
import Info from "../../assets/icons/Info.svg";
import CustomTooltip from "../CustomTootip";
import GoogleIcons from "../../assets/icons/GoogleIcons.svg";
import GoogleLogin from "react-google-login";
import { StyledFormLabel } from "../../pages/Signup/styled";

import { gapi } from "gapi-script";

export default ({ open, setOpen, refetch, br_id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authenticateMail] = useMutation(AUTHENTICATE_MAIL);
  const [authenticateGMailOauth] = useMutation(AUTHENTICATE_GMAIL_OAUTH);
  const [loading, setLoading] = useState(false);
  const [formstate, setFormstate] = useState({ secure: true });

  const gmailClientId = process.env.REACT_APP_CLIENT_ID_GMAIL_OAUTH;
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let { email, password, host, port, secure } = formstate;
      if (!email) {
        enqueueSnackbar("Enter an email Address", {
          variant: "error",
        });
        return;
      }

      if (!host) {
        enqueueSnackbar("Enter a host name", {
          variant: "error",
        });
        return;
      }
      if (!/^\S+@\S+$/i.test(email)) {
        enqueueSnackbar("Enter a valid email Address", {
          variant: "error",
        });
        return;
      }

      ///server
      if (port) port = parseInt(port);
      const { data, errors } = await authenticateMail({
        variables: {
          input: {
            email,
            password,
            host,
            port,
            secure,
            mode: open,
            br_id,
          },
        },
      });
      if (!data && errors?.[0]?.message) {
        enqueueSnackbar(errors[0].message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          data?.authenticateMail?.flag
            ? "Email Address is valid and added successfully"
            : "It seems your email address or settings are invalid",
          {
            variant: data?.authenticateMail?.flag ? "success" : "error",
          }
        );
        if (data?.authenticateMail?.flag) {
          setFormstate({ secure: true });
          handleClose();
          refetch();
        }
      }
    } catch (err) {
      enqueueSnackbar(err, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const googleError = (err) => {
    console.error("Google sign-in failed.", err);
  };

  const onGoogleSuccess = async (googleUser) => {
    try {
      setLoading(true);
      console.log(googleUser, "idTokengoogle2");
      const { code } = googleUser;
      const { data, errors } = await authenticateGMailOauth({
        variables: {
          input: {
            code,
            mode: open,
            br_id,
          },
        },
      });
      if (!data && errors?.[0]?.message) {
        enqueueSnackbar(errors[0].message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          data?.authenticateOAuthGMail?.flag
            ? "Email Address is valid and added successfully"
            : "It seems your email address or settings are invalid",
          {
            variant: data?.authenticateOAuthGMail?.flag ? "success" : "error",
          }
        );
        if (data?.authenticateOAuthGMail?.flag) {
          setFormstate({ secure: true });
          handleClose();
          refetch();
        }
      }
    } catch (error) {
      console.error("Google sign-in failed.", error);
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!open) {
      if (open === "gmail1") {
        setFormstate({ secure: true, host: "smtp.gmail.com", port: 465 });
      } else {
        setFormstate({ secure: true });
      }

      // Initialize Google API client
      const init = () => {
        gapi.load("auth2", () => {
          gapi.auth2.init({
            client_id: gmailClientId,
          });
        });
      };

      if (open === "gmail2") init();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer
        style={{
          // width: "800px",
          width: "680px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography
            sx={{ fontWeight: 700 }}
            id="modal-modal-title"
            variant="h5"
          >
            Add New Email Address -{" "}
            {open === "gmail2" ? (
              "GMail OAuth"
            ) : open === "imap" ? (
              "IMAP/SMTP"
            ) : (
              <>
                {/* using GMail App password */}
                GMail App Password
                <CustomTooltip
                  headerText={<div style={{ fontSize: 18 }}>App Password</div>}
                  bodyText={
                    <ul style={{ fontSize: 15 }}>
                      <li>On your computer, open Gmail.</li>
                      <li>Click the gear icon in the top right corner.</li>
                      <li>Click All Settings.</li>
                      <li>Click the Forwarding and POP/IMAP tab.</li>
                      <li>In the "IMAP access" section, select Enable IMAP.</li>
                      <li>Click Save Changes.</li>
                      <li>Go to your Google Account's Security Settings</li>
                      <li>Enable 2-step verification</li>
                      <li>Create an App password</li>
                    </ul>
                  }
                  noLeft
                >
                  <img
                    src={Info}
                    alt="info"
                    style={{ cursor: "pointer", marginLeft: 10 }}
                  />
                </CustomTooltip>
              </>
            )}
          </Typography>
          <img
            src={RemoveIcon}
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        {/* using Oauth */}
        {open === "gmail2" ? (
          <>
            <ul style={{ fontSize: 18 }}>
              <li>On your computer, open Gmail.</li>
              <li>Click the gear icon in the top right corner.</li>
              <li>Click All Settings.</li>
              <li>Click the Forwarding and POP/IMAP tab.</li>
              <li>In the "IMAP access" section, select Enable IMAP.</li>
              <li>Click Save Changes.</li>
              {/* <li>Go to your Google Workspace Admin Panel</li>
              <li>
                Click <strong>"Add App"</strong>
                and then select <strong>"OAuth App Name or Client ID"</strong>
              </li>
              <li>
                Use the following Client-ID to search for recrowdly:
                <p>{gmailClientId}</p>
              </li>
              <li>
                Select and approve Recrowdly to access your Google Workspace
              </li> */}
            </ul>

            <GoogleLogin
              clientId={gmailClientId}
              render={(renderProps) => (
                <StyledAuthButton
                  disabled={loading}
                  onClick={renderProps.onClick}
                >
                  <img src={GoogleIcons} alt="google icon" />
                  <StyledFormLabel
                    style={{ color: "white", fontSize: "16px" }}
                    sx={{ marginLeft: 2 }}
                  >
                    {loading ? "Loading..." : " Submit"}
                  </StyledFormLabel>
                </StyledAuthButton>
              )}
              cookiePolicy={"single_host_origin"}
              onSuccess={onGoogleSuccess}
              onFailure={googleError}
              accessType="offline"
              prompt="consent"
              responseType="code"
              // scope="https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/userinfo.email"
              scope="https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly"
            />
          </>
        ) : (
          <>
            <Box
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
                display: "flex",
                gap: "20px",
              }}
            >
              <TextField
                label="Email Address"
                type="email"
                required
                sx={{
                  minWidth: 300,
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                onChange={(e) =>
                  setFormstate({ ...formstate, email: e.target.value })
                }
                value={formstate.email}
              />

              <TextField
                label={open === "gmail1" ? "App Password" : "Password"}
                type="password"
                required
                sx={{
                  minWidth: 300,
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                onChange={(e) =>
                  setFormstate({ ...formstate, password: e.target.value })
                }
                value={formstate.password}
              />
            </Box>
            {open !== "gmail1" && (
              <>
                <Box
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <TextField
                    label="Host name"
                    type="text"
                    required
                    sx={{
                      minWidth: 300,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                    onChange={(e) =>
                      setFormstate({ ...formstate, host: e.target.value })
                    }
                    value={formstate.host}
                  />

                  <TextField
                    label="Port"
                    type="number"
                    required
                    sx={{
                      minWidth: 300,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                    onChange={(e) =>
                      setFormstate({ ...formstate, port: e.target.value })
                    }
                    value={formstate.port}
                  />
                </Box>
                <Box
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <IconButton
                    style={{
                      // position: "absolute",
                      // bottom: "8px",
                      // right: "48px",
                      color: "black",
                      fontSize: "12px",
                    }}
                    title="Secure"
                  >
                    <Switch
                      checked={!!formstate.secure}
                      onClick={() =>
                        setFormstate({
                          ...formstate,
                          secure: !formstate.secure,
                        })
                      }
                    />
                    <>Secure</>
                  </IconButton>
                </Box>
              </>
            )}

            <Box
              sx={{
                background: "white",

                width: "100%",
              }}
            >
              <StyledAuthButton
                //   type="submit"
                disabled={loading}
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? "Loading..." : " Submit"}
              </StyledAuthButton>
            </Box>
          </>
        )}

        {/* <Interpolation /> */}
      </ModalContainer>
    </Modal>
  );
};
