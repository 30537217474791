import styled from "@emotion/styled";
import { InputBase, Select } from "@mui/material";

export const AddedSelectTag = styled(Select)`
  height: 4vh;
  border-radius: 6px;
  border: 0.5px solid var(--neutral-palette-platinum-platinum-50, #eeeef2);
  background: var(--neutral-palette-seasalt-seassalt-25, #fdfdfd);
  .MuiSelect-outlined {
    // padding: 4px 8px;
    color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
    font-family: "DM Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    padding-left: 6px;
    padding-right: 30px !important;
  }
`;

export const InputForm = styled(InputBase)`
  background: #fff;
  font-family: "Dm Sans"; 
  /* Element */

  // border: 1px solid ${(props) => props?.borderColor ?? "#d4dae1"};

  border-radius: 12px;
  height: 54px;
  width: 100%;
  margin-bottom: 13px;
  padding-left: 1em;
  margin-top: 6px;
  font-size: 14px;
  &:focus {
    outline: none;
    // border: 1px solid #8d6cff;
    border: ${(props) =>
      props?.mfocus ? ` 1px solid  #007bf7` : "1px solid #1a1b24"};
  }
  border: ${(props) =>
    props?.borderColor
      ? ` 1px solid  ${props?.borderColor}`
      : props?.mfocus
      ? ` 1px solid  #007bf7`
      : "1px solid #D4DAE1"};

  box-shadow: ${(props) =>
    props?.mfocus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};

  &:hover {
    border: ${(props) =>
      props?.mfocus ? ` 1px solid  #007bf7` : "1px solid #1a1b24"};
  }
  @media (max-width: 800px) {
    margin-bottom: 22px;
  }
`;
