import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const StyledHeaderText = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.02em;
  margin-top: 10px;
  margin-bottom: 10px;
`;
