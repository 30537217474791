import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../redux/auth.slice";
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Grid,
  Container,
  Box,
  Divider,
  InputBase,
  Paper,
  IconButton,
  ListItemButton,
  Modal,
  TextField,
  Avatar,
  MenuItem,
  Checkbox,
} from "@mui/material";
import {
  CheckBoxOutlineBlankOutlined,
  DraftsOutlined,
  HomeOutlined,
  InboxOutlined,
  MailOutline,
  ReceiptOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Covete from "../assets/icons/Covete.svg";
import SearchIcon from "../assets/icons/SearchIcon.svg";
import {
  BrandHeader,
  BrandHeaderText,
  DashBoardContainer,
  ModalBoxContainer,
  ModalBoxHeader,
  ModalBoxSubText,
  ModalBoxText,
  ModalButton,
  ModalButtonText,
  ModalColumnContainer,
  ModalImageContainer,
  ModalImageTitle,
  ModalTextField,
  NavContainer,
  NavDivider,
  NavHeaderLogoText,
  NavImage,
  NavSearch,
  NavTitleText,
  ProfileButton,
  ProfileButtonText,
  ProfileImage,
  ProfileImageContainer,
  ProfileImageContent,
  ProfileImageContentHeader,
  ProfileImageContentText,
  ProfileSnippet,
  SearchResultsBarBottom,
  SearchResultsBarText,
  SearchResultsBarTop,
  SearchResultsBrandIcon,
  SearchResultsBrandText,
  SearchResultsForm,
} from "../pages/UserDashboard/styled";
import NavMenuButton from "./NavMenu";
import AvatarImage from "../assets/images/Avatar.svg";
import EditProfileButton from "../assets/images/EditProfileButton.svg";
import AddImage from "../assets/icons/AddImage.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";
import {
  GET_ORGANIZATIONS_FOR_USER,
  GET_USER_ORGANIZATION,
} from "../graphql/queries/organizationQueries";
import { ADD_BRAND } from "../graphql/mutations/brandMutations";
import { GET_BRANDS } from "../graphql/queries/brandQueries";
import AWS from "aws-sdk";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../App.css";
import {
  CheckBoxText,
  PreviousButton,
  PreviousButtonText,
  SignUpContainer,
} from "../pages/OrganizationBrands/styled";
import { GET_INDUSTRIES } from "../graphql/queries/industryQueries";
import ButtonCust from "./ButtonCust";
import FormControlLabel from "@mui/material/FormControlLabel";
import ColorButton from "./ColorButton";
import { StepTitle } from "./Testimonial/ReviewComp/styled";
import CustomTextBox from "./CustomTextBox";
import CustomSelectBox from "./CustomSelectBox";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: "us-east-1",
});

const s3 = new AWS.S3();

export default function StepTwoBrand({
  formData,
  handleInputChange,
  onPrevious,
  onNext,
  orgName,
  styleStep,
  errorMessages = {},
}) {
  const [value, setValue] = useState("");
  const [focusState, setFocusState] = useState({});

  const isMobile = useSelector((state) => state.responsive.isMobile);
  const [showResults, setShowResults] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [openBrand, setBrandOpen] = React.useState(false);

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");
  const [isChecked, setIsChecked] = useState(false);

  // const [name, setName] = useState("");
  // const [description, setDescription] = useState("");
  // const [sector, setSector] = useState("");
  // const [organization, setOrganization] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  // const [brandUrl, setBrandUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [showText, setShowText] = useState("");
  const [showImage, setShowImage] = useState(true);
  const [src, setSrc] = useState("");
  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);

  const [nameError, setNameError] = useState("");
  const [sectorError, setSectorError] = useState("");
  const [brandUrlError, setBrandUrlError] = useState("");
  const [descError, setDescError] = useState("");

  // const industryData = {}; // Define your industryData here
  // const orgName = ""; // Define your orgName here
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  const { error: industryError, data: industryData } = useQuery(GET_INDUSTRIES);
  let fileUrl = "";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Add function to toggle visibility of search results
  const toggleResults = () => {
    setShowResults(!showResults);
    setShowProfile(false);
  };

  // Add function to toggle visibility of profile
  const toggleProfile = () => {
    setShowProfile(!showProfile);
    setShowResults(false);
  };
  const handleBrandOpen = () => {
    setBrandOpen(true);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);

      const reader = new FileReader();

      reader.addEventListener("load", () => setSrc(reader.result));
      // reader.addEventListener("load", () => console.log(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShowCrop(true);
    }
  };

  // console.log(formData, "Form dataBrand");
  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // setCrop(percentCrop);
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        setBlob(blob);
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const uploadToS3 = async () => {
    if (croppedImageUrl) {
      // Initialize S3 object
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      let addParams = Date.now();
      // Upload image to S3 bucket
      const key = `Image${addParams}.${blob.type.split("/")[1]}`;

      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: key,
        Body: blob,
      };
      try {
        const data = await s3.upload(params).promise();

        setOpenSnackbar(true);
        setSnackbarMessage("Image Added Successfuly");
        handleInputChange("imageUrl", addParams.toString());
        setShowCrop(false);
        // setCroppedImageUrl(null);
      } catch (err) {
        console.error("Upload Error", error);
        setOpenSnackbar(true);
        setSnackbarMessage("Upload Error");
      }
    }
  };
  const handleCropCancel = () => {
    setSrc("");
    setCroppedImageUrl(null);
    setShowCrop(false);
  };
  // console.log(src)
  // console.log(croppedImageUrl)
  // console.log(showCrop)
  // console.log(imageUrl)
  const validateUrl = (url) => {
    const pattern =
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;
    return pattern.test(url);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   if (
  //     name === "" ||
  //     sector === "" ||
  //     brandUrl === ""
  //   ) {
  //     setOpenSnackbar(true);
  //     return setSnackbarMessage("Please return all fields");
  //   }

  //   try {
  //     await createBrand({
  //       variables: {
  //         input: { name, sector, organization,brandUrl, imageUrl, description },
  //       },
  //     });
  //     setOpenSnackbar(true);
  //     setSnackbarMessage("Brand added successfully");
  //     setName("");

  //     setDescription("");
  //     setOrganization("");
  //     setSector("");
  //     setImageUrl("");
  //     setBrandUrl("");
  //     setShowCrop(false)
  //     onNext()
  //   } catch (error) {
  //     console.error("Error creating brand:", error);
  //     setOpenSnackbar(true);
  //     setSnackbarMessage("Error creating brand");
  //   }
  // };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // get organizations for select
  const { error, data } = useQuery(GET_ORGANIZATIONS_FOR_USER);

  const handleClose = () => {
    setOpen(false);
    setShowResults(!showResults);
  };
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log('Before update:', formData.brand);
  //   handleInputChange((prevFormData) => {
  //     console.log('Previous form data:', prevFormData);
  //     const updatedBrand = {
  //       ...prevFormData.brand,
  //       [name]: value,
  //     };
  //     console.log('Updated brand:', updatedBrand);
  //     return {
  //       ...prevFormData,
  //       brand: updatedBrand,
  //     };
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   onNext();
  // };
  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />

      <SignUpContainer>
        {isMobile && <StepTitle>Create Brand</StepTitle>}
        <ModalImageTitle>Organization</ModalImageTitle>
        <BrandHeader isMobile={isMobile}>
          <BrandHeaderText>{orgName}</BrandHeaderText>
        </BrandHeader>
        <ModalImageTitle>Add Image</ModalImageTitle>
        <div>
          <div
            className="upload-container"
            style={{ display: "flex", position: "relative" }}
          >
            <div
              className="upload-box"
              style={{
                width: "100px",
                height: "100px",
                background: "#F7F7F7",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "7px",
                marginTop: "0.5rem",
              }}
              onMouseEnter={() => {
                setShowText(true);
                setShowImage(false);
              }}
              onMouseLeave={() => {
                setShowText(false);
                setShowImage(true);
              }}
              onClick={() => document.getElementById("image-upload").click()}
            >
              {!croppedImageUrl && (
                <>
                  {showImage && <img src={AddImage} />}
                  {showText && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={EditProfileButton}
                        style={{ width: "20px" }}
                        alt="edit-profile-button"
                      />
                      <p
                        className="upload-text"
                        style={{
                          fontSize: "10px",
                          lineHeight: "12px",
                          fontFamily: "Dm Sans",
                          // color: "#FFFFFF",
                          color: "#1a1b24",
                          paddingLeft: "0.5rem",
                          paddingRight: "0.5rem",
                          marginTop: "10px",
                          textAlign: "center",
                          borderRadius: "5px",
                          textShadow: "0px 1px 4px rgba(0, 0, 0, 0.35)",
                        }}
                      >
                        Add Image
                      </p>
                    </div>
                  )}
                </>
              )}
              {croppedImageUrl && (
                <img
                  src={croppedImageUrl}
                  alt="Cropped image"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "100px",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
            {showCrop && (
              <div
                className="crop-container"
                style={{
                  padding: "10px",
                  position: "absolute",
                  marginLeft: "7rem",
                  zIndex: "5",
                  background: "#FFFFFF",
                  border: "1px solid #ECECF2",
                  boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
                  top: isMobile && "100px",
                  left: isMobile && "-100%",
                  width: isMobile ? "80vw" : "20vw",
                }}
              >
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                  ruleOfThirds
                  // style={{ width: 200, height: 200 }}
                />
                <div
                  className="crop-buttons"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ColorButton
                    onClick={uploadToS3}
                    title="Save"
                    width="45%"
                    background="#1A1B24"
                  ></ColorButton>
                  <ColorButton
                    onClick={handleCropCancel}
                    title="Cancel"
                    width="45%"
                  >
                    Cancel
                  </ColorButton>
                </div>
              </div>
            )}
            <input
              type="file"
              id="image-upload"
              accept="image/*"
              onChange={onSelectFile}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <form style={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <ModalTextField style={{ width: isMobile ? "100%" : "49%" }}>
              <ModalImageTitle>
                Name <span style={{ color: "#4097DA" }}>*</span>
              </ModalImageTitle>
              <CustomTextBox
                name="name"
                focusState={focusState}
                setFocusState={setFocusState}
                margin="normal"
                error={!!errorMessages.name}
                helperText={errorMessages.name}
                required
                fullWidth
                id="name"
                label=""
                placeholder="Enter your brand name"
                autoComplete="name"
                defaultValue={formData.name}
                autoFocus
                onChange={(e) => {
                  handleInputChange("name", e.target.value);
                }}
              />
            </ModalTextField>
            <ModalTextField style={{ width: isMobile ? "100%" : "49%" }}>
              <ModalImageTitle>
                Industry <span style={{ color: "#4097DA" }}>*</span>
              </ModalImageTitle>
              <CustomSelectBox
                margin="normal"
                id="sector"
                error={!!errorMessages.sector}
                helperText={errorMessages.sector}
                select
                defaultValue=""
                placeholder="Choose an Option" // Placeholder text
                onChange={(e) => {
                  handleInputChange("sector", e.target.value);
                }}
                formStyle={{ marginTop: "16px" }}
                name="industry"
                focusState={focusState}
                setFocusState={setFocusState}
                fullWidth
                arrValues={
                  industryData && industryData.industrys instanceof Array
                    ? industryData.industrys.map((industry) => ({
                        name: industry.name,
                        value: industry.name,
                      }))
                    : []
                }
              />
              {/* {industryData &&
                industryData.industrys.map((industry) => (
                  <MenuItem key={industry._id} value={industry.name}>
                    {industry.name}
                  </MenuItem>
                ))} */}
            </ModalTextField>
          </Box>

          <ModalTextField>
            <ModalImageTitle>
              Domain 
            </ModalImageTitle>
            <CustomTextBox
              margin="normal"
              required
              error={!!errorMessages.brandUrl}
              helperText={errorMessages.brandUrl}
              fullWidth
              id="url"
              label=""
              placeholder="Add Brand Url"
              name="url"
              focusState={focusState}
              setFocusState={setFocusState}
              autoComplete="url"
              defaultValue={formData.brandUrl}
              onChange={(e) => {
                handleInputChange("brandUrl", e.target.value);
              }}
            />
          </ModalTextField>
          {/* <ModalTextField>
                    <ModalImageTitle>Organization</ModalImageTitle>
                    <TextField
                        id='organizationId'
                        select
                        defaultValue=""
                        onChange={(e) => {
                          handleInputChange({ ...formData, organization: e.target.value });
                        }}
                        sx={{ width: "95%", height: "46px" ,marginTop:"0.5rem"}}
                      size="small"
                      >
                        
                        {data && data.organizationsForUser.map((organization) => (
                          <MenuItem key={organization._id} value={organization._id}>
                          {organization.name}
                        </MenuItem>
                        ))}
                      </TextField>
                  </ModalTextField> */}
          <ModalTextField>
            <ModalImageTitle>
              Description <span style={{ color: "#4097DA" }}>*</span>
            </ModalImageTitle>
            <CustomTextBox
              margin="normal"
              fullWidth
              error={!!errorMessages.description}
              helperText={errorMessages.description}
              id="description"
              label=""
              placeholder="Brief info on Organization"
              name="description"
              focusState={focusState}
              setFocusState={setFocusState}
              defaultValue={formData.description}
              multiline
              noheight
              rows={4}
              onChange={(e) => {
                handleInputChange("description", e.target.value);
              }}
            />
          </ModalTextField>
          {/* <ModalButton type="submit">
                    <ModalButtonText>Create Brand</ModalButtonText>
                  </ModalButton> */}
        </form>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: "1rem",
          }}
        >
          <FormControlLabel
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "fit-content",
              marginTop: "1rem",
            }}
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                name="brandOwnership"
              />
            }
            label={
              <CheckBoxText component="span">
                I hereby declare that&nbsp;
                <b>{orgName}</b>
                &nbsp;owns this brand
              </CheckBoxText>
            }
            sx={{ marginRight: "0px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "1.5rem",
            marginBottom: isMobile && "70px",
          }}
        >
          <PreviousButton
            onClick={onPrevious}
            style={{ visibility: styleStep ? "visible" : "hidden" }}
          >
            {" "}
            <PreviousButtonText>Previous</PreviousButtonText>
          </PreviousButton>
          <ColorButton
            onClick={onNext}
            title={"Continue"}
            height={"80px"}
            width={isMobile ? "40%" : "201px"}
            disabled={!isChecked}
            background="#1A1B24"
            pointerEvents={isChecked ? "auto" : "none"}
          />
        </div>
      </SignUpContainer>
    </>
  );
}
