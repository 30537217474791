import styled from "@emotion/styled";
import { Box, Button, Divider, Typography } from "@mui/material";

export const calculatePxToPercentage = (size) => {
  const actualVal = (size / 1920) * 100;
  return `${actualVal}vw`;
};

export const Wrapper = styled(Box)(({ theme }) => ({
  padding: calculatePxToPercentage(20),
  // paddingRight: calculatePxToPercentage(40),
}));
export const CustomText = styled(Typography)(({ theme }) => ({
  "&.heading-text": {
    color: "#282833",
    textAlign: "center",
    fontSize: calculatePxToPercentage(16),
    fontWeight: 500,
    lineHeight: calculatePxToPercentage(19.2),
  },
  "&.sort-text": {
    color: "#282833B2",
    textAlign: "left",
    fontSize: calculatePxToPercentage(16),
    fontWeight: 500,
    lineHeight: calculatePxToPercentage(20.8),
  },
}));

export const Column = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  "&.parent": {
    gap: calculatePxToPercentage(10),
    justifyContent: "center",
    // alignItems: 'center',
  },
  "&.child": {
    justifyContent: "center",
    alignItems: "center",
    gap: calculatePxToPercentage(10),
    borderRadius: calculatePxToPercentage(20),
    width: calculatePxToPercentage(120),
    height: calculatePxToPercentage(120),
    transition: "background-color 0.3s ease",
    "&:hover": {
      background: "#F4F4FA",
    },
  },
}));

export const FlexRow = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const AppBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "47px",
  padding: "11.5px",
  borderRadius: "14px",

  //   height: calculatePxToPercentage(60),
  //   width: calculatePxToPercentage(60),
  //   minWidth: calculatePxToPercentage(60),
  border: "1px solid #E0E0E9",
  cursor: "pointer",
  //   borderRadius: calculatePxToPercentage(18),
  transition: "none",
  "&:hover": {
    background: "#F4F4FA",
    border: "1px solid #B8B8CD",
  },
}));
