import React, { useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Divider, Snackbar } from "@mui/material";
import { GET_BRAND } from "../../graphql/queries/brandQueries";
import Spinner from "../../components/Spinner";
import { DELETE_BRAND } from "../../graphql/mutations/brandMutations";
import { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import {
  OverallBrandNameConatiner,
  BrandNameBox,
  BrandName,
  TabDetails,
  TabContainer,
  TabSelected,
  DetailsContainer,
  OrganizationDetailsText,
  OrganizationDetails,
  OrganizationDetailsTextTwo,
  EditBrand,
  EditBrandText,
  DeleteBrand,
  DeleteBrandText,
  OptionsText,
  BadgeBox,
} from "./styled";
import BrandAnalytics from "../../components/BrandAnalytics";
import Templates from "../Templates/templates";
import VideoAnalytics from "../../components/VideoAnalytics";
import { useSelector, useDispatch } from "react-redux";
import { logOutMenu } from "../../redux/menu.slice";
import UpdateBrandDialog from "../../components/UpdateBrandDialog";
import Widget from "../Widget";
import Customization from "../Customization";
import Installation from "../Installation";
import SubHeader from "../../components/SubHeader";
import {
  BannerBox,
  BannerBoxText,
  BannerBoxUrl,
  BannerButton,
  BannerButtonText,
  BannerCalender,
  BannerContainer,
  BannerHeader,
  BannerSubText,
  BannerText,
  FilterButton,
  FilterButtonIcon,
  FilterButtonText,
  FilterButtonTwo,
  FlexBannerButtonFilter,
  FlexBannerButtonFilterTwo,
  FlexBannerContainer,
  InsightButton,
  SearchResultsFormDate,
  SearchResultsFormDateTwo,
} from "../UserDashboard/styled";
import ButtonCust from "../../components/ButtonCust";
import Dots from "../../assets/images/Dots.svg";
import ReviewButton from "../../assets/images/ReviewButton.svg";
import AngleArrow from "../../assets/images/AngleArrow.svg";

import Plus from "../../assets/icons/Plus.svg";
import Upload from "../../assets/icons/Upload.svg";
import GIcon from "../../assets/icons/GoogleIcon.svg";
import {
  PreviousButton,
  PreviousButtonText,
} from "../OrganizationBrands/styled";
import moment from "moment";
import { setDate } from "../../redux/date.slice";
import dayjs from "dayjs";
import { DatePicker, Select } from "antd";
import styled from "styled-components";

import FilterIcon from "../../assets/icons/Filter.svg";
import { OptionItem, OptionText } from "../TeamMembers/styled";
import EllipsisLink from "../../components/EllipsisLink";
import Modal from "react-modal";
import SpinnerBig from "../../components/SpinnerBig";
import RequestDialog from "../../components/RequestDialog";
import EllipsisLinkBrand from "../../components/EllipsisLink/EllipsisLink";
import EditDeleteButton from "../../components/EditDeleteButton";
import WidgetDialog from "../../components/WidgetDialog";
import DashboardTable from "../../components/DashboardTable";
import { TabTitle } from "../../utils/HeaderTitle";
import ColorButton from "../../components/ColorButton";
import UploadDialog from "../../components/UploadDialog";
import { openStatus } from "../../redux/check.slice";
import {
  FETCH_LEADS,
  FETCH_NEW_LEADS_COUNT,
} from "../../graphql/queries/leads";
import LeadsComp from "../UserDashboard/leadsComp";
import GoogleReviews from "../GoogleReviews";
import { FETCH_REVIEWS } from "../../graphql/queries/googleReview";
import PageList from "../../components/PageList";
import WorkflowList from "../../components/WorkflowList";
import CampaignList from "../../components/CampaignList";
import SalesList from "../../components/SalesList";
import { imgFormat } from "../../utils/format";
import EmailAddress from "../../components/EmailAddress";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//only production

const isReleased = process.env.REACT_APP_NODE_ENV === "production";

export default function Brand() {
  let { tabValue: tabValue } = useParams();
  const tabNumber = tabValue ? parseInt(tabValue) : 0;
  const [value, setValue] = useState(tabNumber);
  const dispatch = useDispatch();
  const rangeRef = useRef(null);

  const [dialogRequestOpen, setDialogRequestOpen] = useState(false);
  const [dialogUploadOpen, setDialogUploadOpen] = useState(
    sessionStorage.getItem("uploadProgressFlag") === "1"
  );
  const [customRangeSelected, setCustomRangeSelected] = useState(false);
  const menu = useSelector((state) => state.menu);
  const check = useSelector((state) => state.check);
  TabTitle(`${menu.name} || Insights`);

  // console.log(menu, "Menu");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [scrollToReview, setScrollToReview] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [selectedDays, setSelectedDays] = useState(1);
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [showResults, setShowResults] = useState(false);
  const numSelectedDays = dayjs(endDate).diff(startDate, "day") + 1;
  const [dateText, setDateText] = useState("Yesterday");
  const [display, setDisplay] = useState(false);
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const { data: dataLeads, refetch: fetchLeads } = useQuery(FETCH_LEADS, {
    variables: {
      input: { br_id: menu.id },
    },
  });
  const { data: countData, refetch: refetchNewCount } = useQuery(
    FETCH_NEW_LEADS_COUNT,
    {
      variables: {
        input: { br_id: menu.id },
      },
    }
  );

  const refetchLeads = useCallback((variables = {}) => {
    refetchNewCount();
    fetchLeads(variables);
  }, []);

  const {
    data: dataReviews,
    refetch: refetchReviews,
    loading: loadingReviews,
  } = useQuery(FETCH_REVIEWS, {
    variables: {
      input: { br_id: menu.id },
    },
  });

  // Update dateText whenever selectedDays changes
  useEffect(() => {
    let dateText;
    if (numSelectedDays == "1") {
      dateText = "Today";
    } else if (numSelectedDays == "2") {
      dateText = "Yesterday";
    } else {
      dateText = `Last ${numSelectedDays} days`;
    }
    setDateText(dateText);
  }, [numSelectedDays]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setScrollToReview(false);
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { brandId } = useParams();
  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(GET_BRAND, {
    variables: { input: { _id: menu.id } },
  });

  // console.log(data, "Branddata");
  // useEffect(() => {
  //   navigate(`/brands/${menu.brandId}/0/impression`);
  // }, [menu.brandId, navigate]);

  // useEffect(() => {
  //   const closeOutsideDiv = (e) => {
  //     if (rangeRef.current && display && !rangeRef.current.contains(e.target)) {
  //       setDisplay(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", closeOutsideDiv);

  //   return () => document.removeEventListener("mousedown", closeOutsideDiv);
  // }, [rangeRef, display]);

  const toggleResults = () => {
    setShowResults((prevState) => !prevState);
  };
  const handleRangeChangeTwo = (dates, dateStrings) => {
    const newStartDate = dateStrings[0];
    const newEndDate = dateStrings[1];

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    dispatch(setDate({ startDate: newStartDate, endDate: newEndDate }));
    const isCustomRangeSelected =
      dateStrings[0] === dayjs().subtract(2, "day").format("YYYY-MM-DD") &&
      dateStrings[1] === dayjs().format("YYYY-MM-DD");

    setCustomRangeSelected(isCustomRangeSelected);

    // Close the date picker only if custom range is not selected
    if (!isCustomRangeSelected) {
      setDisplay(false);
    }
  };

  // console.log(analyticsData)

  function getCalendarContainer(trigger) {
    return trigger.parentNode;
  }
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const handleRequestDialog = () => {
    // setDialogRequestOpen(true);
    navigate(`/request_testimonial/${data?.brand?.brandId}`);
  };
  const handleUploadDialog = () => {
    setDialogUploadOpen(true);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Plus
  let addOptionArray = isMobile
    ? [
        {
          title: (
            <div
              style={{
                minWidth: "105px",
                display: "flex",
                alignItems: "center",
                // borderBottom: "1px solid #ECECF2",
                padding: "10px 0",
              }}
            >
              <img src={AngleArrow} alt="review-icon" />
              <OptionsText style={{ marginLeft: "10px" }}>
                Request Testimonials
              </OptionsText>
            </div>
          ),
          link: handleRequestDialog,
        },
        {
          title: (
            <div
              style={{
                minWidth: "105px",
                display: menu?.owned ? "flex" : "none",
                alignItems: "center",
                // borderBottom: "1px solid #ECECF2",
                padding: "10px 0",
              }}
            >
              <img src={Plus} alt="invite-icon" />
              <OptionsText style={{ marginLeft: "20px" }}>Invite</OptionsText>
            </div>
          ),
          link: () => navigate(`/invitation/${data?.brand?.organization?._id}`),
        },
        {
          title: (
            <div
              style={{
                minWidth: "105px",
                display: menu?.owned ? "flex" : "none",
                alignItems: "center",
                // borderBottom: "1px solid #ECECF2",
                padding: "10px 0",
              }}
            >
              <img src={Upload} alt="upload-icon" />
              <OptionsText style={{ marginLeft: "10px" }}>
                Upload Testimonials
              </OptionsText>
            </div>
          ),
          link: handleUploadDialog,
        },
      ]
    : [
        {
          title: (
            <div
              style={{
                minWidth: "105px",
                display: menu?.owned ? "flex" : "none",
                alignItems: "center",
                // borderBottom: "1px solid #ECECF2",
                padding: "10px 0",
              }}
            >
              <img src={Plus} alt="invite-icon" />
              <OptionsText style={{ marginLeft: "10px" }}>Invite</OptionsText>
            </div>
          ),
          link: () => navigate(`/invitation/${data?.brand?.organization?._id}`),
        },
      ];

  let newItemCountFetchLeads = countData?.fetchNewLeadCount?.count;

  useEffect(() => {
    if (sessionStorage.getItem("uploadProgressFlag") !== "1")
      setDialogUploadOpen(false);
  }, [sessionStorage.getItem("uploadProgressFlag")]);

  // console.log(dialogUploadOpen, "open dialog");
  if (loading) return <SpinnerBig big />;

  if (error) return <p>Something Went Wrong</p>;

  // console.log("Fetched Leads:", dataLeads);

  console.log("Fetched Leads:", dataLeads);
  if (dataLeads && dataLeads.fetchLeads) {
    dataLeads.fetchLeads?.data?.forEach((lead) => {
      console.log("Lead Email:", lead.email);
      // You can access other properties of the lead object similarly
    });
  }

  let tbItemNumber = 0;
  let tbItemCompNumber = 0;

  return (
    <>
      <WidgetDialog
        openDialog={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      {!loading && !error && (
        <OverallBrandNameConatiner>
          {/* <SubHeader
            btnTitle=""
            btnLink="/brands/"
            arrLink={[
              { title: "Dashboard", link: "/" },
              { title: "Insight Page" },
            ]}
          /> */}
          <BannerContainer>
            <FlexBannerContainer>
              <BannerHeader>
                <Box>
                  {data?.brand.imageUrl ? (
                    <BannerBoxUrl
                      background={data?.brand.imageUrl}
                      isMobile={isMobile}
                    ></BannerBoxUrl>
                  ) : (
                    <BannerBox background={data?.brand.brandColor}>
                      <BannerBoxText>
                        {data?.brand.name.charAt(0).toUpperCase() || ""}
                      </BannerBoxText>
                    </BannerBox>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginLeft: "1rem",
                  }}
                >
                  <BannerText isMobile={isMobile}>
                    {data?.brand.name
                      ? data.brand.name.charAt(0).toUpperCase() +
                        data.brand.name.slice(1)
                      : "Brand"}
                  </BannerText>
                  {!isMobile && (
                    <BannerSubText isMobile={isMobile}>
                      Here are some more detailed analytics of{" "}
                      {data?.brand.name || "brand"} for you to work around.
                    </BannerSubText>
                  )}
                </Box>
              </BannerHeader>
              <FlexBannerButtonFilterTwo>
                {!isMobile && (
                  // link to testimonial page
                  <Link
                    to={`/request_testimonial/${data?.brand?.brandId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <ColorButton
                      style={{ cursor: "pointer" }}
                      width="190px"
                      background="#1A1B24"
                      title="Request Testimonials"
                      alt="review Button"
                      fontWeight="0"
                      // onClick={handleRequestDialog}
                    />
                  </Link>
                )}
                {menu?.owned &&
                  !isMobile &&
                  sessionStorage.getItem("uploadProgressFlag") !== "1" && (
                    <PreviousButton
                      style={{
                        width: "212px",

                        // marginLeft: "25px",
                        height: "48px",
                        justifyContent: "center",
                      }}
                      onClick={handleUploadDialog}
                      focus={dialogUploadOpen}
                    >
                      <img src={Upload} alt="Upload" />
                      <PreviousButtonText
                      // style={{
                      //   width: "36px",
                      // }}
                      >
                        Upload Testimonials
                      </PreviousButtonText>
                    </PreviousButton>
                  )}

                {/* hello */}
                {(menu?.access === "edit" || isMobile) && (
                  <EditDeleteButton
                    addOptionArray={addOptionArray}
                    removeLine
                    containerStyle={{ marginLeft: 0 }}
                  />
                )}
              </FlexBannerButtonFilterTwo>
            </FlexBannerContainer>
          </BannerContainer>
          <BannerContainer>
            <FlexBannerContainer
              style={{ flexDirection: isMobile ? "column" : "row" }}
            >
              <BannerHeader
                style={{
                  width: isMobile ? "100%" : "70%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginBottom: isMobile && "24px",
                    // marginLeft: "1rem",
                  }}
                >
                  <OrganizationDetailsText>Description</OrganizationDetailsText>
                  <BannerSubText>{data?.brand.description} </BannerSubText>
                </Box>
              </BannerHeader>
              <FlexBannerButtonFilterTwo
                style={{
                  width: isMobile ? "100%" : "30%",
                  maxWidth: "300px",
                }}
              >
                <DetailsContainer
                  style={{
                    justifyContent: "space-between",
                    // flexDirection: "column",
                  }}
                >
                  {/* <h4 onClick={handleOpenDialog}>Leave review</h4> */}
                  <OrganizationDetails>
                    <OrganizationDetailsText>Sector</OrganizationDetailsText>
                    <OrganizationDetailsTextTwo>
                      {data?.brand.organization.industry}
                    </OrganizationDetailsTextTwo>
                  </OrganizationDetails>
                  <OrganizationDetails sx={{ marginLeft: "10px" }}>
                    <OrganizationDetailsText>
                      Organization
                    </OrganizationDetailsText>
                    <OrganizationDetailsTextTwo>
                      {data?.brand.organization.name}
                    </OrganizationDetailsTextTwo>
                  </OrganizationDetails>
                </DetailsContainer>
              </FlexBannerButtonFilterTwo>
            </FlexBannerContainer>
          </BannerContainer>

          <TabDetails>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TabContainer>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    background: "#F7F7F7",
                    borderRadius: "13px",
                    padding: "0px 5px !important",
                    width: "max-content",
                    // width: "100%",
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#F7F7F7",
                      display: "none",
                    },
                  }}
                >
                  <TabSelected
                    isMobile={isMobile}
                    label="Analytics"
                    {...a11yProps(tbItemNumber)}
                    sx={{
                      backgroundColor:
                        value === tbItemNumber
                          ? "#D4DAE1 !important"
                          : "#F7F7F7",
                      // margin: value === 0 ? "5px !important" : "0px",
                      borderRadius:
                        value === tbItemNumber ? "15px  !important" : "0px ",
                      margin: "5px 0px",
                      ":hover": {
                        backgroundColor: "#EEEEF2",
                        borderRadius: "15px",
                      },
                      // Apply background color to the selected tab based on value prop
                    }}
                  />
                  {++tbItemNumber}
                  <TabSelected
                    isMobile={isMobile}
                    label="Videos"
                    {...a11yProps(tbItemNumber)}
                    sx={{
                      backgroundColor:
                        value === 1 ? "#D4DAE1 !important" : "#F7F7F7",
                      margin: "5px 0px",
                      // margin: value === 1 ? "5px !important" : "0px",
                      borderRadius: value === 1 ? "15px  !important" : "0px ",

                      ":hover": {
                        backgroundColor: "#EEEEF2",
                        borderRadius: "15px",
                      },
                      // Apply background color to the selected tab based on value prop
                    }}
                  />
                  {++tbItemNumber}
                  <TabSelected
                    isMobile={isMobile}
                    label="Customization"
                    {...a11yProps(tbItemNumber)}
                    sx={{
                      backgroundColor:
                        value === tbItemNumber
                          ? "#D4DAE1 !important"
                          : "#F7F7F7",
                      // margin: value === 2 ? "5px !important" : "0px",
                      borderRadius:
                        value === tbItemNumber ? "15px  !important" : "0px ",
                      margin: "5px 0px",
                      ":hover": {
                        backgroundColor: "#EEEEF2",
                        borderRadius: "15px",
                      },
                      // Apply background color to the selected tab based on value prop
                    }}
                  />
                  {++tbItemNumber}
                  <TabSelected
                    isMobile={isMobile}
                    label="Installation"
                    {...a11yProps(tbItemNumber)}
                    sx={{
                      backgroundColor:
                        value === tbItemNumber
                          ? "#D4DAE1 !important"
                          : "#F7F7F7",
                      // margin: value === 3 ? "5px !important" : "0px",
                      borderRadius:
                        value === tbItemNumber ? "15px  !important" : "0px ",
                      margin: "5px 0px",
                      ":hover": {
                        backgroundColor: "#EEEEF2",
                        borderRadius: "15px",
                      },
                      // Apply background color to the selected tab based on value prop
                    }}
                  />

                  {++tbItemNumber}
                  {menu?.owned && (
                    <TabSelected
                      isMobile={isMobile}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <>Leads</>
                          {newItemCountFetchLeads ? (
                            <BadgeBox>{newItemCountFetchLeads}</BadgeBox>
                          ) : null}
                        </div>
                      }
                      {...a11yProps(tbItemNumber)}
                      sx={{
                        backgroundColor:
                          value === tbItemNumber
                            ? "#D4DAE1 !important"
                            : "#F7F7F7",
                        // margin: value === 3 ? "5px !important" : "0px",
                        borderRadius:
                          value === tbItemNumber ? "15px  !important" : "0px ",
                        margin: "5px 0px",
                        ":hover": {
                          backgroundColor: "#EEEEF2",
                          borderRadius: "15px",
                        },
                        // Apply background color to the selected tab based on value prop
                      }}
                    />
                  )}
                  {false && menu?.owned && (
                    <TabSelected
                      isMobile={isMobile}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <img src={GIcon} alt="" />
                          <>Google Reviews</>
                          {/* {newItemCountFetchLeads ? (
                            <BadgeBox>{newItemCountFetchLeads}</BadgeBox>
                          ) : null} */}
                        </div>
                      }
                      {...a11yProps(5)}
                      sx={{
                        backgroundColor:
                          value === 5 ? "#D4DAE1 !important" : "#F7F7F7",
                        // margin: value === 3 ? "5px !important" : "0px",
                        borderRadius: value === 5 ? "15px  !important" : "0px ",
                        margin: "5px 0px",
                        ":hover": {
                          backgroundColor: "#EEEEF2",
                          borderRadius: "15px",
                        },
                        // Apply background color to the selected tab based on value prop
                      }}
                    />
                  )}
                  {/* {++tbItemNumber} */}
                  {false && menu?.owned && (
                    <TabSelected
                      isMobile={isMobile}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          Page Builder
                        </div>
                      }
                      {...a11yProps(tbItemNumber)}
                      sx={{
                        backgroundColor:
                          value === tbItemNumber
                            ? "#D4DAE1 !important"
                            : "#F7F7F7",
                        // margin: value === 3 ? "5px !important" : "0px",
                        borderRadius:
                          value === tbItemNumber ? "15px  !important" : "0px ",
                        margin: "5px 0px",
                        ":hover": {
                          backgroundColor: "#EEEEF2",
                          borderRadius: "15px",
                        },
                        // Apply background color to the selected tab based on value prop
                      }}
                    />
                  )}
                  {++tbItemNumber}
                  {!isReleased && menu?.owned && (
                    <TabSelected
                      isMobile={isMobile}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          Workflows
                        </div>
                      }
                      {...a11yProps(tbItemNumber)}
                      sx={{
                        backgroundColor:
                          value === tbItemNumber
                            ? "#D4DAE1 !important"
                            : "#F7F7F7",
                        // margin: value === 3 ? "5px !important" : "0px",
                        borderRadius:
                          value === tbItemNumber ? "15px  !important" : "0px ",
                        margin: "5px 0px",
                        ":hover": {
                          backgroundColor: "#EEEEF2",
                          borderRadius: "15px",
                        },
                        // Apply background color to the selected tab based on value prop
                      }}
                    />
                  )}
                  {++tbItemNumber}
                  {!isReleased && menu?.owned && (
                    <TabSelected
                      isMobile={isMobile}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <>Newsletters</>
                        </div>
                      }
                      {...a11yProps(tbItemNumber)}
                      sx={{
                        backgroundColor:
                          value === tbItemNumber
                            ? "#D4DAE1 !important"
                            : "#F7F7F7",
                        borderRadius:
                          value === tbItemNumber ? "15px  !important" : "0px ",
                        margin: "5px 0px",
                        ":hover": {
                          backgroundColor: "#EEEEF2",
                          borderRadius: "15px",
                        },
                      }}
                    />
                  )}
                  {++tbItemNumber}
                  {!isReleased && menu?.owned && (
                    <TabSelected
                      isMobile={isMobile}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <>Campaign</>
                        </div>
                      }
                      {...a11yProps(tbItemNumber)}
                      sx={{
                        backgroundColor:
                          value === tbItemNumber
                            ? "#D4DAE1 !important"
                            : "#F7F7F7",
                        borderRadius:
                          value === tbItemNumber ? "15px  !important" : "0px ",
                        margin: "5px 0px",
                        ":hover": {
                          backgroundColor: "#EEEEF2",
                          borderRadius: "15px",
                        },
                      }}
                    />
                  )}
                  {++tbItemNumber}
                  {!isReleased && menu?.owned && (
                    <TabSelected
                      isMobile={isMobile}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <>Sales</>
                        </div>
                      }
                      {...a11yProps(tbItemNumber)}
                      sx={{
                        backgroundColor:
                          value === tbItemNumber
                            ? "#D4DAE1 !important"
                            : "#F7F7F7",
                        borderRadius:
                          value === tbItemNumber ? "15px  !important" : "0px ",
                        margin: "5px 0px",
                        ":hover": {
                          backgroundColor: "#EEEEF2",
                          borderRadius: "15px",
                        },
                      }}
                    />
                  )}
                  {++tbItemNumber}
                  {!isReleased && menu?.owned && (
                    <TabSelected
                      isMobile={isMobile}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <>Email Addresses</>
                        </div>
                      }
                      {...a11yProps(tbItemNumber)}
                      sx={{
                        backgroundColor:
                          value === tbItemNumber
                            ? "#D4DAE1 !important"
                            : "#F7F7F7",
                        borderRadius:
                          value === tbItemNumber ? "15px  !important" : "0px ",
                        margin: "5px 0px",
                        ":hover": {
                          backgroundColor: "#EEEEF2",
                          borderRadius: "15px",
                        },
                      }}
                    />
                  )}
                </Tabs>
              </TabContainer>
              <DetailsContainer>
                {/* <EditBrand onClick={handleOpenDialog}>
                  <EditBrandText>Edit Brand</EditBrandText>
                </EditBrand>
                <DeleteBrand onClick={handleDelete}>
                  <DeleteBrandText>Delete Brand</DeleteBrandText>
                </DeleteBrand> */}
                <RequestDialog
                  openDialog={dialogRequestOpen}
                  brand={data?.brand}
                  onClose={() => setDialogRequestOpen(false)}
                  isMobile={isMobile}
                />
                {/* should not be available if an active upload is ongoing... so we need to set sessionstorage */}
                <UploadDialog
                  openDialog={dialogUploadOpen}
                  brand={data?.brand}
                  onClose={() => setDialogUploadOpen(false)}
                  isMobile={isMobile}
                />
              </DetailsContainer>
            </Box>
            <TabPanel
              value={value}
              index={tbItemCompNumber}
              sx={{ padding: "0px" }}
            >
              <BrandAnalytics />
            </TabPanel>
            <TabPanel value={value} index={++tbItemCompNumber}>
              <DashboardTable
                id={menu.brandId}
                name={menu.name}
                brandID={menu.id}
                days={selectedDays}
                startDate={startDate}
                endDate={endDate}
                itemsRemoval={true}
                isMobile={isMobile}
              />
            </TabPanel>
            <TabPanel value={value} index={++tbItemCompNumber}>
              <Customization
                brandId={menu.brandId}
                _id={menu.id}
                data={data?.brand}
                handleRefetch={refetch}
                isMobile={isMobile}
              />
            </TabPanel>
            <TabPanel value={value} index={++tbItemCompNumber}>
              <Installation
                brandId={menu.brandId}
                _id={menu.id}
                isMobile={isMobile}
                data={data?.brand}
                scrollToReview={scrollToReview}
                setScrollToReview={setScrollToReview}
                reload={refetch}
              />
            </TabPanel>
            {menu?.owned && (
              <>
                <TabPanel value={value} index={++tbItemCompNumber}>
                  <LeadsComp
                    rows={dataLeads?.fetchLeads?.data ?? []}
                    page={dataLeads?.fetchLeads?.page}
                    newItemCount={newItemCountFetchLeads}
                    reload={refetchLeads}
                    br_id={menu.id}
                    brandId={menu.brandId}
                    brandObj={data?.brand}
                    changeToInstallation={() => {
                      setScrollToReview(true);
                      setValue(3);
                    }}
                  />
                </TabPanel>

                {/* <TabPanel value={value} index={5}>
                  <GoogleReviews
                    br_id={menu.id}
                    brandId={menu.brandId}
                    reviewData={dataReviews?.fetchReviews}
                    loading={loadingReviews}
                    reload={refetchReviews}
                  />
                </TabPanel> */}

                {/* <TabPanel value={value} index={++tbItemCompNumber}>
                  <PageList br_id={menu.id} brandId={menu.brandId} />
                </TabPanel> */}

                <TabPanel value={value} index={++tbItemCompNumber}>
                  <WorkflowList br_id={menu.id} brandId={menu.brandId} />
                </TabPanel>

                <TabPanel value={value} index={++tbItemCompNumber}>
                  <Templates />
                </TabPanel>

                <TabPanel value={value} index={++tbItemCompNumber}>
                  <CampaignList br_id={menu.id} />
                </TabPanel>

                <TabPanel value={value} index={++tbItemCompNumber}>
                  <SalesList br_id={menu.id} />
                </TabPanel>

                <TabPanel value={value} index={++tbItemCompNumber}>
                  <EmailAddress br_id={menu.id} />
                </TabPanel>
              </>
            )}
          </TabDetails>
        </OverallBrandNameConatiner>
      )}
    </>
  );
}
