import { calculatePxToPercentage } from "./styled";
import React from "react";

const AppBoxIcon = ({ onHover }) => {
  return (
    <svg
      width={calculatePxToPercentage(30)}
      height={calculatePxToPercentage(30)}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75391 8.13281C8.75391 8.47799 8.47408 8.75781 8.12891 8.75781C7.78373 8.75781 7.50391 8.47799 7.50391 8.13281C7.50391 7.78763 7.78373 7.50781 8.12891 7.50781C8.47408 7.50781 8.75391 7.78763 8.75391 8.13281"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.25 8.13281C16.25 8.47799 15.9702 8.75781 15.625 8.75781C15.2798 8.75781 15 8.47799 15 8.13281C15 7.78763 15.2798 7.50781 15.625 7.50781C15.9702 7.50781 16.25 7.78763 16.25 8.13281"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 8.13281C23.75 8.47799 23.4702 8.75781 23.125 8.75781C22.7798 8.75781 22.5 8.47799 22.5 8.13281C22.5 7.78763 22.7798 7.50781 23.125 7.50781C23.4702 7.50781 23.75 7.78763 23.75 8.13281"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75391 15.6289C8.75391 15.9741 8.47408 16.2539 8.12891 16.2539C7.78373 16.2539 7.50391 15.9741 7.50391 15.6289C7.50391 15.2837 7.78373 15.0039 8.12891 15.0039C8.47408 15.0039 8.75391 15.2837 8.75391 15.6289"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.25 15.6289C16.25 15.9741 15.9702 16.2539 15.625 16.2539C15.2798 16.2539 15 15.9741 15 15.6289C15 15.2837 15.2798 15.0039 15.625 15.0039C15.9702 15.0039 16.25 15.2837 16.25 15.6289"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 15.6289C23.75 15.9741 23.4702 16.2539 23.125 16.2539C22.7798 16.2539 22.5 15.9741 22.5 15.6289C22.5 15.2837 22.7798 15.0039 23.125 15.0039C23.4702 15.0039 23.75 15.2837 23.75 15.6289"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75391 23.1289C8.75391 23.4741 8.47408 23.7539 8.12891 23.7539C7.78373 23.7539 7.50391 23.4741 7.50391 23.1289C7.50391 22.7837 7.78373 22.5039 8.12891 22.5039C8.47408 22.5039 8.75391 22.7837 8.75391 23.1289"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.25 23.1289C16.25 23.4741 15.9702 23.7539 15.625 23.7539C15.2798 23.7539 15 23.4741 15 23.1289C15 22.7837 15.2798 22.5039 15.625 22.5039C15.9702 22.5039 16.25 22.7837 16.25 23.1289"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 23.1289C23.75 23.4741 23.4702 23.7539 23.125 23.7539C22.7798 23.7539 22.5 23.4741 22.5 23.1289C22.5 22.7837 22.7798 22.5039 23.125 22.5039C23.4702 22.5039 23.75 22.7837 23.75 23.1289"
        stroke="#323232"
        stroke-opacity="0.7"
        stroke-width="1.87422"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AppBoxIcon;
