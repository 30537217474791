import { Grid, Paper, Box, Container } from "@mui/material";
import { TabTitle } from "../../utils/HeaderTitle";
import { StyledHeaderText } from "./styled";

export default () => {
  TabTitle("Privacy Policy");
  return (
    <Container>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        spacing={2}
        sx={{
          height: "100%",
          p: 5,
        }}
        component={Paper}
        elevation={0}
        square
      >
        <Box>
          <StyledHeaderText style={{ fontSize: "40px" }}>
            Privacy Policy
          </StyledHeaderText>
          <StyledHeaderText style={{ display: "inline-block" }}>
            Effective Date
          </StyledHeaderText>{" "}
          September 10,2024
          <StyledHeaderText>1. Overview</StyledHeaderText>
          Welcome to Punch Agency ("we," "us," or "our"). We value your privacy
          and are committed to protecting your personal data. This Privacy
          Policy outlines how we handle your information when you use our
          website{" "}
          <a href="https://www.recrowdly.com">https://www.recrowdly.com</a> and
          services.
          <StyledHeaderText>2. Information We Collect</StyledHeaderText>
          <ul>
            <li>
              <StyledHeaderText>Personal Information</StyledHeaderText>: We may
              collect personal information such as your name, email address, and
              phone number when you register, subscribe, or interact with our
              services.
            </li>
            <li>
              <StyledHeaderText>Non-Personal Information</StyledHeaderText>:
              This includes data that does not identify you personally, such as
              browser type, device information, and data collected via cookies
              or other tracking technologies.
            </li>
          </ul>
          <StyledHeaderText>3. How We Use Your Information</StyledHeaderText>
          We use your information for the following purposes:
          <ul>
            <li>To provide and maintain our services.</li>
            <li>
              To process transactions and send related information, such as
              confirmations and invoices.
            </li>
            <li>
              To respond to your comments, questions, and customer service
              requests.
            </li>
            <li>To improve our website and customize user experience.</li>
          </ul>
          <StyledHeaderText>4. Sharing Your Information</StyledHeaderText>
          We do not sell or trade your personal data. We may share your
          information in the following circumstances:
          <ul>
            <li>
              <StyledHeaderText>With Service Providers</StyledHeaderText>: We
              may share your data with third-party service providers who help us
              deliver our services, such as payment processors and analytics
              providers.
            </li>
            <li>
              <StyledHeaderText>Legal Requirements</StyledHeaderText>: We may
              disclose your information if required by law, such as in response
              to a subpoena, or if we believe that such action is necessary to
              comply with legal obligations. providers.
            </li>
          </ul>
          <StyledHeaderText>5. Security of Your Information</StyledHeaderText>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we strive to protect
          your data, no method of transmission over the internet or electronic
          storage is 100% secure.
          <StyledHeaderText>6. Your Data Protection Rights</StyledHeaderText>
          Depending on your location, you may have rights regarding your
          personal data, including the right to access, correct, or delete your
          data. To exercise these rights, please contact us at{" "}
          <a href="mailto:support@punch.cool">support@punch.cool</a>.
          <StyledHeaderText>7. Links to Other Websites</StyledHeaderText>
          Our website may contain links to other websites that are not operated
          by us. We are not responsible for the content or privacy practices of
          these third-party sites.
          <StyledHeaderText>8. Changes to This Privacy Policy</StyledHeaderText>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page. You
          are advised to review this Privacy Policy periodically for any
          changes.
          <StyledHeaderText>9. Contact Us</StyledHeaderText>
          If you have any questions about this Privacy Policy, please contact us
          at:
          <ul>
            <li>
              <StyledHeaderText style={{ display: "inline-block" }}>
                Email:
              </StyledHeaderText>{" "}
              support@punch.cool
            </li>
            <li>
              <StyledHeaderText style={{ display: "inline-block" }}>
                Address:
              </StyledHeaderText>{" "}
              805 Avenida Ponce de Leon, Unit 1202, San Juan, 00907
            </li>
          </ul>
        </Box>
      </Grid>
    </Container>
  );
};
