import { createSlice } from "@reduxjs/toolkit";
const punchSlice = createSlice({
  name: "punch",
  initialState: {
    punchDetails: null,
  },
  reducers: {
    setPunchDetails: (state, action) => {
      state.punchDetails = action.payload;
    },

    removePunchDetails: (state) => {
      state.punchDetails = null;
    },
  },
});

export const punchReducer = punchSlice.reducer;

export const { setPunchDetails, removePunchDetails } = punchSlice.actions;
