import { useState } from "react";

import Popover from "@mui/material/Popover";
import {
  Wrapper,
  CustomText,
  Column,
  FlexRow,
  calculatePxToPercentage,
  AppBox,
} from "./styled";

import Zoom from "@mui/material/Zoom";
import TrackerAppIcon from "./TrackerAppIcon";
import PunchAppIcon from "./PunchAppIcon";
import RecrowdlyAppIcon from "./RecrowdlyAppIcon";
import AppBoxIcon from "./AppBoxIcon";

export default () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (url) => {
    window.open(url, "_blank");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <AppBox onClick={handleClickToggle}>
        <AppBoxIcon />
      </AppBox>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Zoom}
        transitionDuration={0}
        PaperProps={{
          style: {
            width: calculatePxToPercentage(289),
            height: "fit-content",
            marginTop: calculatePxToPercentage(15),
            marginLeft: calculatePxToPercentage(-30),
            borderRadius: calculatePxToPercentage(20),
            boxShadow: "0px 4px 10px rgba(80, 88, 159, 0.2)",
          },
        }}
        disableScrollLock={true}
      >
        <Wrapper>
          <Column className="parent">
            <FlexRow>
              <Column
                className="child"
                onClick={() => handleClick("https://tracker.zwilt.com/")}
              >
                <TrackerAppIcon />
                <CustomText className="heading-text">Zwilt Tracker</CustomText>
              </Column>
              <Column
                className="child"
                onClick={() => handleClick("https://www.punch.cool/")}
              >
                <PunchAppIcon />
                <CustomText className="heading-text">Punch</CustomText>
              </Column>
            </FlexRow>
            <FlexRow>
              <Column
                className="child"
                onClick={() =>
                  handleClick(
                    process.env.REACT_APP_NODE_ENV === "production"
                      ? "https://app.zwilt.com/"
                      : "https://staging.zwilt.com/"
                  )
                }
              >
                <TrackerAppIcon />
                <CustomText className="heading-text">Zwilt App</CustomText>
              </Column>
            </FlexRow>
          </Column>
        </Wrapper>
      </Popover>
    </>
  );
};
