import { Box } from "@mui/system";
import React, { useState, useRef } from "react";
import Layout from "../../components/Layout";
// import CropImage from '../../components/CropImage'
import {
  BoxHeader,
  BoxHeaderSubText,
  BoxHeaderTitle,
  BoxImage,
  BoxImageButton,
  BoxImageButtonText,
  BoxImageText,
  BoxSection,
} from "./styled";
import SpaceShip from "../../assets/images/SpaceShip.svg";
import { Divider, TextField, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";

import { GET_ORGANIZATIONS_FOR_USER, GET_USER_ORGANIZATION } from "../../graphql/queries/organizationQueries";
import { ADD_ORGANIZATION } from "../../graphql/mutations/organizationMutation";
import { GET_INDUSTRIES } from "../../graphql/queries/industryQueries";
import AWS from "aws-sdk";
import AddImage from "../../assets/icons/AddImage.svg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../App.css";
import {
  ModalBoxContainer,
  ModalBoxHeader,
  ModalBoxSubText,
  ModalBoxText,
  ModalButton,
  ModalButtonText,
  ModalColumnContainer,
  ModalImageContainer,
  ModalImageTitle,
  ModalTextField,
} from "../../pages/UserDashboard/styled";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: "us-east-1",
});

const s3 = new AWS.S3();

function ReusableModal({ openDialog, onClose, }) {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");
  const [name, setName] = useState("");
  const [domainUrl, setDomainUrl] = useState("");
  const [industry, setIndustry] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [showText, setShowText] = useState("");
  const [showImage, setShowImage] = useState(true);
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);

  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  let fileUrl = "";

  const [createOrganization] = useMutation(ADD_ORGANIZATION, {
    variables: { input: { name, domainUrl, industry, imageUrl, description } },
    update(cache, { data: { createOrganization } }) {
      const existingData = cache.readQuery({ query: GET_USER_ORGANIZATION });
      const { userOrganizations = [] } = existingData || {};
  
      const updatedUserOrganizations = userOrganizations.map((userOrganization) => {
        if (userOrganization.organization._id === createOrganization._id) {
          const updatedOrganizations = [...userOrganization.organization, createOrganization];
  
          return {
            ...userOrganization,
            organization: updatedOrganizations,
          };
        }
        return userOrganization;
      });
  
      cache.writeQuery({
        query: GET_USER_ORGANIZATION,
        data: { userOrganizations: updatedUserOrganizations },
      });
    },
    refetchQueries: [{ query: GET_USER_ORGANIZATION }],
  });
  
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);

      const reader = new FileReader();
      
      reader.addEventListener("load", () => setSrc(reader.result));
      // reader.addEventListener("load", () => console.log(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShowCrop(true);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // setCrop(percentCrop);
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        setBlob(blob);
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const uploadToS3 = async () => {
    if (croppedImageUrl) {
      // Initialize S3 object
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      let addParams = Date.now();
      // Upload image to S3 bucket
      const key = `Image${addParams}.${blob.type.split("/")[1]}`;
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: key,
        Body: blob,
      
      };
      try {
        const data = await s3.upload(params).promise();
      
        setOpenSnackbar(true);
        setSnackbarMessage("Image Added Successfuly");

        setImageUrl(addParams.toString());
      } catch (err) {
        console.error("Upload Error", error);
        setOpenSnackbar(true);
        setSnackbarMessage("Upload Error");
      }
    }
  };
  const handleCropCancel = () => {
    setSrc(null);
    setCroppedImageUrl(null);
    setShowCrop(false);
  };
 
  const validateUrl = (url) => {
    const pattern =
      /^[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;
    return pattern.test(url);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    

    if (
      name === "" ||
      domainUrl === "" ||
      industry === "" ||
      description === ""
    ) {
      setOpenSnackbar(true);
      return setSnackbarMessage("Please return all fields");
    }
    if (!validateUrl(domainUrl)) {
      setOpenSnackbar(true);
      return setSnackbarMessage("Invalid domain URL");
    }
    try {
      await createOrganization({
        variables: {
          input: { name, domainUrl, industry, imageUrl, description },
        },
      });
      setOpenSnackbar(true);
      setSnackbarMessage("Organization added successfully");
      setName("");
      setIndustry("");
      setDescription("");
      setDomainUrl("");
      setImageUrl("");
      // close the modal
      onClose()
      
    } catch (error) {
      console.error("Error creating organization:", error);
      setOpenSnackbar(true);
      setSnackbarMessage("Error creating organization");
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const { error, data } = useQuery(GET_INDUSTRIES);

  // dialog modal
  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
      <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
        

        <Dialog
          open={openDialog}
    
          onClose={onClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="md"
        >
          {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
          <DialogContent>
            <ModalBoxContainer>
              <ModalBoxHeader>
                <ModalBoxText>Let's get you started</ModalBoxText>
                <ModalBoxSubText>
                  Fill the relevant information to create an organization
                </ModalBoxSubText>
              </ModalBoxHeader>
              <Divider sx={{ marginTop: "0.5rem" }} />

              <ModalColumnContainer>
                <ModalImageTitle>Add Image orgn</ModalImageTitle>
                <div>
                  <div className="upload-container" style={{display:"flex"}}>
                    <div
                      className="upload-box"
                      style={{
                        width: "20%",
                        height: "100px",
                        background: "#eee",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "7px",
                        marginTop: "0.5rem",
                      }}
                      onMouseEnter={() => {
                        setShowText(true);
                        setShowImage(false);
                      }}
                      onMouseLeave={() => {
                        setShowText(false);
                        setShowImage(true);
                      }}
                      onClick={() =>
                        document.getElementById("image-upload").click()
                      }
                    >
                      {!croppedImageUrl && (
                        <>
                          {showImage && <img src={AddImage} />}
                          {showText && (
                            <p
                              className="upload-text"
                              style={{
                                background:
                                  "linear-gradient(90deg, #8D6CFE 0%, #F2B02F 100%)",
                                fontSize: "0.75rem",
                                padding: "0.5rem",
                                marginTop: "1rem",
                                borderRadius: "5px",
                              }}
                            >
                              Upload Image
                            </p>
                          )}
                        </>
                      )}
                      {croppedImageUrl && (
                        <img src={croppedImageUrl} alt="Cropped image" style={{width:"100%",height:"100%"}} />
                      )}
                    </div>
                    {showCrop && (
                      <div className="crop-container" style={{ padding: "10px",
                        
                        marginLeft: "4rem",
                        width: "45%",background: "#FFFFFF",
                        border: "1px solid #ECECF2",
                        boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)"}}>
                        <ReactCrop
                          src={src}
                          crop={crop}
                          onImageLoaded={onImageLoaded}
                          onComplete={onCropComplete}
                          onChange={onCropChange}
                          ruleOfThirds
                          // style={{ width: 200, height: 200 }}
                        />
                         <div className="crop-buttons" style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                          <button onClick={uploadToS3}    style={{
                                background:
                                  "linear-gradient(90deg, #8D6CFE 0%, #F2B02F 100%)",
                                fontSize: "0.75rem",
                                padding: "10px 20px",
                                marginTop: "0.5rem",
                                borderRadius: "5px",
                                border: "none",
    width: "45%",
    color: "white"

                              }}>save</button>
                          <button onClick={handleCropCancel} style={{
                            background:
                              "linear-gradient(90deg, #8D6CFE 0%, #F2B02F 100%)",
                            fontSize: "0.75rem",
                            padding: "10px 20px",
                            marginTop: "0.5rem",
                            borderRadius: "5px",
                            border: "none",
width: "45%",
color: "white"

                          }}>Cancel</button>
                        </div>
                      </div>
                    )}
                    <input
                      type="file"
                      id="image-upload" 
                      accept="image/*"
                      onChange={onSelectFile}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                <form onSubmit={onSubmit}>
                  <ModalTextField>
                    <ModalImageTitle>Organization Name</ModalImageTitle>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label=""
                      placeholder="Enter your organization name"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      sx={{ width: "95%", height: "46px" }}
                      size="small"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </ModalTextField>
                  <ModalTextField>
                    <ModalImageTitle>Url</ModalImageTitle>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="url"
                      label=""
                      placeholder="Add Domain Url"
                      name="url"
                      autoComplete="url"
                      autoFocus
                      sx={{ width: "95%", height: "46px" }}
                      size="small"
                      onChange={(e) => setDomainUrl(e.target.value)}
                    />
                  </ModalTextField>
                  <ModalTextField>
                    <ModalImageTitle>Industry</ModalImageTitle>
                    <TextField
                     id='industry'
                     select
                     defaultValue=""
                     onChange={(e) => setIndustry(e.target.value)}
                     sx={{ width: "95%", height: "46px" ,marginTop:"0.5rem"}}
                   size="small"
                    >
                      {data &&
                        data.industrys.map((industry) => (
                          <MenuItem key={industry._id} value={industry.name}>
                          {industry.name}
                        </MenuItem>
                        ))}
              
        
                    </TextField>
                  </ModalTextField>
                  <ModalTextField>
                    <ModalImageTitle>Description</ModalImageTitle>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="description"
                      label=""
                      placeholder="Add description to your organization"
                      name="description"
                      multiline
                      rows={4}
                      sx={{ width: "95%" }}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </ModalTextField>
                  <ModalButton type="submit">
                    <ModalButtonText>Create Organization</ModalButtonText>
                  </ModalButton>
                </form>
              </ModalColumnContainer>
              <div></div>
            </ModalBoxContainer>
          </DialogContent>
        </Dialog>
      </>

  );
}

export default ReusableModal;
