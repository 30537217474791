import { Box } from "@mui/system";
import React, { useState, useRef } from "react";
import Layout from "../../components/Layout";
import CropImage from "../../components/CropImage";
import {
  BoxHeader,
  BoxHeaderSubText,
  BoxHeaderTitle,
  BoxImage,
  BoxImageButton,
  BoxImageButtonBrand,
  BoxImageButtonText,
  BoxImageText,
  BoxSection,
  PreviousButton,
  PreviousButtonText,
  SkipButton,
} from "../OrganizationBrands/styled";
import Tangled from "../../assets/images/Tangled.svg";
import SpaceShip from "../../assets/images/SpaceShip.svg";
import { Divider, TextField, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";

import {
  GET_ORGANIZATIONS_FOR_USER,
  GET_USER_ORGANIZATION,
} from "../../graphql/queries/organizationQueries";
import {
  ADD_ORGANIZATION,
  USER_OGRANIZATION_INVITE,
} from "../../graphql/mutations/organizationMutation";
import { GET_INDUSTRIES } from "../../graphql/queries/industryQueries";
import AWS from "aws-sdk";
import AddImage from "../../assets/icons/AddImage.svg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../App.css";
import {
  ModalBoxContainer,
  ModalBoxHeader,
  ModalBoxSubText,
  ModalBoxText,
  ModalButton,
  ModalButtonText,
  ModalColumnContainer,
  ModalImageContainer,
  ModalImageTitle,
  ModalTextField,
} from "../UserDashboard/styled";
import UserDashboard from "../UserDashboard";
import AddBrandDialog from "../../components/AddBrandDialog";
import SpinnerBig from "../../components/SpinnerBig";
import ReusableModal from "../../components/AddModal";
import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "../../redux/menu.slice";
import { TabTitle } from "../../utils/HeaderTitle";
import { setDate } from "../../redux/date.slice";
import moment from "moment";
import StepTwoBrand from "../../components/AddBrandModal";
import StepOneOrganization from "../../components/AddOrganizationModal";
import SignUpInvitation from "../Invitation/SignUpInvitation";
import { useNavigate } from "react-router-dom";
import { ADD_BRAND } from "../../graphql/mutations/brandMutations";
import Invitation from "../Invitation";
import { useSnackbar } from "notistack";
import ProgressBar from "../../components/ProgressBar";
import ButtonCust from "../../components/ButtonCust";
import SubHeader from "../../components/SubHeader";
import ColorButton from "../../components/ColorButton";
// const Step1 = ({ onNext }) => {
//   const [value, setValue] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onNext();
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <h2>Step 1</h2>
//       <input
//         type="text"
//         value={value}
//         onChange={(e) => setValue(e.target.value)}
//         placeholder="Enter value"
//         required
//       />
//       <button type="submit">Next</button>
//     </form>
//   );
// };

// const Step2 = ({ onNext }) => {
//   const [value, setValue] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onNext();
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <h2>Step 2</h2>
//       <input
//         type="text"
//         value={value}
//         onChange={(e) => setValue(e.target.value)}
//         placeholder="Enter value"
//         required
//       />
//       <button type="submit">Next</button>
//     </form>
//   );
// };

function CreateOrganization() {
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const menu = useSelector((state) => state.menu);
  TabTitle(`Create`);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");
  // const [name, setName] = useState("");
  // const [domainUrl, setDomainUrl] = useState("");
  // const [industry, setIndustry] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  // const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [showCrop, setShowCrop] = useState(false);
  // const [showText, setShowText] = useState("");
  // const [showImage, setShowImage] = useState(true);
  // const [src, setSrc] = useState(null);
  // const [file, setFile] = useState(null);
  // const [blob, setBlob] = useState(null);
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const [dialogOrganizationOpen, setDialogOrganizationOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [step, setStep] = useState(1);
  const [inviteState, setInviteState] = useState({});
  const [numberOfInput, setNumberOfInput] = useState(2);
  const [btnLoading, setBtnLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState({});
  // console.log(inviteState, "inviteState");
  const [formDataOrganization, setFormDataOrganization] = useState({
    name: "",
    industry: "",
    // other organization fields
  });
  const [formDataBrand, setFormDataBrand] = useState({
    name: "",
    description: "",
    sector: "",
    organization: "",
    imageUrl: "",
    brandUrl: "",

    // other brand fields
  });
  const [organizationDataOne, setOrganizationData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createOrganization] = useMutation(
    ADD_ORGANIZATION
    //   , {
    //   update(cache, { data: { createOrganization } }) {
    //     const { organizationsForUser } = cache.readQuery({
    //       query: GET_ORGANIZATIONS_FOR_USER,
    //     });

    //     cache.writeQuery({
    //       query: GET_ORGANIZATIONS_FOR_USER,
    //       data: {
    //         organizationsForUser: [...organizationsForUser, createOrganization],
    //       },
    //     });
    //   },
    // }
  );
  const [createBrand] = useMutation(
    ADD_BRAND
    // , {
    // update(cache, { data: { createBrand } }) {
    //   const existingData = cache.readQuery({ query: GET_USER_ORGANIZATION });
    //   const { userOrganizations = [] } = existingData || {};

    //   const updatedUserOrganizations = userOrganizations.map(
    //     (userOrganization) => {
    //       if (
    //         userOrganization.organization._id === formDataBrand.organization
    //       ) {
    //         const updatedBrands = [...userOrganization.brands, createBrand];

    //         return {
    //           ...userOrganization,
    //           brands: updatedBrands,
    //         };
    //       }
    //       return userOrganization;
    //     }
    //     );

    //     cache.writeQuery({
    //       query: GET_USER_ORGANIZATION,
    //       data: { userOrganizations: updatedUserOrganizations },
    //     });
    //   },
    //   refetchQueries: [{ query: GET_USER_ORGANIZATION }],
    // }
  );
  const [inviteUser] = useMutation(USER_OGRANIZATION_INVITE);
  const { error, data } = useQuery(GET_INDUSTRIES);
  const { data: organizationData, loading: organizationLoading } = useQuery(
    GET_USER_ORGANIZATION
  );
  // dialog modal
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  let emailReq =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // const handleOpenDialog = () => {
  //   setDialogOpen(true);
  // };
  // const handleOpenOrganizationDialog = () => {
  //   setDialogOrganizationOpen(true);
  // };

  if (organizationLoading) {
    return <SpinnerBig />;
  }

  const handleNext = async (e) => {
    e?.preventDefault();
    // if (formDataOrganization.name.trim() === '') {
    //   setErrorMessage('Please enter the organization name.');
    //   return;
    // }
    // if (formDataOrganization.industry.trim() === '') {
    //   setErrorMessage('Please choose an industry');
    //   return;
    // }
    try {
      // Submit organization data
      const { data } = await createOrganization({
        variables: {
          input: {
            name: formDataOrganization.name,
            industry: formDataOrganization.industry,
          },
          // other organization fields
        },
      });

      const createdOrganization = data.createOrganization;
      // console.log(createdOrganization);
      setOrganizationData(createdOrganization);
      setOpenSnackbar(true);
      setSnackbarMessage("Organization added successfully");
      // Reset the form data to empty strings

      // Set the organization data in formDataBrand
      setFormDataBrand((prevFormData) => ({
        ...prevFormData,
        organization: createdOrganization._id, // Update with the response from createOrganization
      }));
      setFormDataOrganization({
        name: "",
        industry: "",
        // other organization fields
      });

      // Handle success or display appropriate feedback
    } catch (error) {
      // Handle error or display appropriate feedback
      // console.log("error", error);
    }
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };
  const steps = ["Create Organization", "Create Brand", "Invite Members"];
  const getBrandAccess = (brandInput = []) => {
    // console.log("getBrandAccess", brandInput);
    return brandInput.map((item) => ({
      access: item?.sub ?? "view",
      brand: item?.value,
    }));
  };

  const handleFormSubmit = async () => {
    // Perform final submission of organization and brand data
    try {
      // Submit brand data
      // await createBrand({
      //   variables: {
      //     input: {
      //       name: formDataBrand.name,
      //       sector: formDataBrand.sector,
      //       organization: formDataBrand.organization,
      //       brandUrl: formDataBrand.brandUrl,
      //       imageUrl: formDataBrand.imageUrl,
      //       description: formDataBrand.description,
      //     },
      //   },
      // });
      // setOpenSnackbar(true);
      // setSnackbarMessage("brand added successfully");
      let messageArr = [];

      try {
        setBtnLoading(true);
        let errorFlag = false;
        for (let i = 0; i < numberOfInput; i++) {
          if (inviteState[`error${i}`]) {
            messageArr.push({
              type: "error",
              description: `Error: Please make sure all the email addresses are valid`,
            });
            return;
          }
          if (
            inviteState[`email${i}`] &&
            inviteState[`email${i}`].trim() !== "" &&
            !inviteState[`brand${i}`]
          ) {
            messageArr.push({
              type: "error",
              description: `Error: Empty brand assigned to ${inviteState[
                `email${i}`
              ]?.trim()}  `,
            });
            errorFlag = true;
          }
        }
        if (errorFlag) return;

        for (let i = 0; i < numberOfInput; i++) {
          let emailInput = `email${i}`;
          let brandInputValue = inviteState[`brand${i}`]; //use later
          let emailInputValue = inviteState[emailInput]?.toString().trim();

          if (emailReq.test(emailInputValue)) {
            try {
              //send to server
              let { data: dataInvite, errors: errorInvite } = await inviteUser({
                variables: {
                  input: {
                    invitedUserEmail: emailInputValue,
                    organization: organizationDataOne._id,
                    brands: getBrandAccess(brandInputValue),
                  },
                },
              });
              if (dataInvite) {
                messageArr.push({
                  type: "success",
                  description: `${emailInputValue} successfully invited `,
                });
              } else {
                if (
                  errorInvite &&
                  errorInvite instanceof Array &&
                  errorInvite[0]?.message
                ) {
                  messageArr.push({
                    type: "error",
                    description: `Error occured with ${emailInputValue} : ${errorInvite[0]?.message}   `,
                  });
                } else {
                  messageArr.push({
                    type: "error",
                    description: `Error occured with ${emailInputValue}   `,
                  });
                }
              }
            } catch (err) {
              messageArr.push({
                type: "error",
                description: `${emailInputValue} not invited: ${err}  `,
              });
            }
          }
        }
      } catch (e) {
        // console.log(e);
        messageArr.push({
          type: "error",
          description: `Error occurred... ${e} `,
        });
      } finally {
        setBtnLoading(false);
        showMessage(messageArr);
        navigate("/");
      }
      // Handle success or display appropriate feedback
    } catch (error) {
      // Handle error or display appropriate feedback
      // console.log("error", error);
    }
    setStep(step + 1);
  };
  const validateUrl = (url) => {
    const pattern =
      /^[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;
    return pattern.test(url);
  };

  const handleBrandSubmit = async () => {
    // Perform final submission of organization and brand data

    const newErrorMessages = {};

    if (formDataBrand.name.trim() === "") {
      newErrorMessages.name = "Name is required";
    }
    if (!formDataBrand.sector || formDataBrand.sector.trim() === "") {
      newErrorMessages.sector = "Industry is required";
    }
    if (!formDataBrand.description || formDataBrand.description.trim() === "") {
      newErrorMessages.description = "Description is required";
    }

    if (
      formDataBrand.brandUrl.trim() !== "" &&
      !validateUrl(formDataBrand.brandUrl)
    ) {
      newErrorMessages.brandUrl = "Please add https://";
    }
    setErrorMessage(newErrorMessages);

    if (Object.keys(newErrorMessages).length === 0) {
      try {
        // Submit brand data
        await createBrand({
          variables: {
            input: {
              name: formDataBrand.name,
              sector: formDataBrand.sector,
              organization: formDataBrand.organization,
              brandUrl: formDataBrand.brandUrl,
              imageUrl: formDataBrand.imageUrl,
              description: formDataBrand.description,
            },
          },
        });
        setOpenSnackbar(true);
        setSnackbarMessage("Brand added successfully");
        let messageArr = [];
        return true;
        // Handle success or display appropriate feedback
      } catch (error) {
        // Handle error or display appropriate feedback
        // console.log("error", error);
        return false;
      }
    }
  };
  const handleInputChange = (field, value) => {
    setFormDataBrand({ ...formDataBrand, [field]: value });

    // Clear the error message for the specific field
    setErrorMessage((prevErrorMessages) => ({
      ...prevErrorMessages,
      [field]: "",
    }));
  };
  const showMessage = (messageArr) => {
    if (messageArr.length > 0) {
      for (let item of messageArr) {
        enqueueSnackbar(item?.description, { variant: item?.type });
      }
    }
    return;
  };
  const handleBrandNext = async () => {
    const isSubmitSuccessful = await handleBrandSubmit();
    if (isSubmitSuccessful) {
      setStep(step + 1);
    }
  };

  return (
    <>
      <>
        {isMobile && (
          <SubHeader arrLink={[{ title: "Dashboard", link: "/" }]} />
        )}

        <Snackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          autoHideDuration={3000}
        />

        <>
          <div>
            <ProgressBar
              isMobile={isMobile}
              steps={steps}
              activeStep={step - 1}
            />
            {step === 1 && (
              <StepOneOrganization
                formData={formDataOrganization}
                setFormData={setFormDataOrganization}
                onNext={handleNext}
              />
            )}

            {step === 2 && (
              <StepTwoBrand
                formData={formDataBrand}
                onPrevious={handlePrevious}
                onNext={handleBrandNext}
                orgName={organizationDataOne.name}
                styleStep={false}
                errorMessages={errorMessage}
                handleInputChange={handleInputChange}
              />
            )}
            {step === 3 && (
              <Invitation
                inputNumber={2}
                inputOrganization={organizationDataOne._id}
                description="You can update user permissions on the team page after setting up."
                setInviteFormstate={setInviteState}
                onPrevious={handlePrevious}
                removeLayout={true}
              />
            )}

            {step === 3 && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "70%",
                      maxWidth: "769px",

                      margin: "1.5rem auto",
                    }}
                  >
                    <PreviousButton onClick={handlePrevious}>
                      <PreviousButtonText>Previous</PreviousButtonText>
                    </PreviousButton>
                    <ColorButton
                      onClick={handleFormSubmit}
                      title="Finish"
                      width={isMobile ? "45%" : "201px"}
                      background="#1A1B24"
                    />
                  </div>
                </div>
                {!isMobile && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "769px",
                      margin: "2rem auto",
                    }}
                  >
                    <SkipButton onClick={() => navigate("/")}>
                      Skip for now
                    </SkipButton>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      </>
    </>
  );
}

export default CreateOrganization;
