import { useEffect, useRef, useState } from "react";
import { Container, DivText, OptionDivTwo, OptionItemTwo } from "./styled";
import Dots from "../../assets/images/Dots.svg";
import BottomPopup from "../BottomPopup";
import { PreviousButton } from "../../pages/OrganizationBrands/styled";
import { useSelector } from "react-redux";
import React from "react";
/**
 * A component that displays a button with ellipsis (...) to trigger a dropdown menu.
 * @component
 * @param {Object} props - The component's props.
 * @param {Array} props.content - An array of options to display in the dropdown menu.
 * @param {number} props.index - The index of the component.
 * @param {boolean} props.last - A boolean indicating whether this is the last component.
 * @param {Object} props.style - Additional inline styles for the button.
 * @returns {JSX.Element} - The rendered component.
 */
export default function EllipsisLinkBrand({
  content,
  index,
  last,
  style = {},
  styleText = {},
  title,
}) {
  const [display, setDisplay] = useState(false);
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const divRef = useRef(null);

  /**
   * Displays the content of the dropdown menu.
   * @returns {JSX.Element} - The rendered dropdown menu content.
   */
  const displayContent = () => {
    if (content instanceof Array) {
      return content.map((el, ind, arr) => {
        return (
          <OptionItemTwo
            last={arr.length - 1 === ind}
            onClick={() => {
              el?.link && el.link();
              setDisplay(false);
            }}
            key={`${index}_${ind}`}
            isMobile={isMobile}
          >
            {el?.title}
          </OptionItemTwo>
        );
      });
    }
    return null;
  };

  useEffect(() => {
    /**
     * Closes the dropdown when clicking outside of it.
     * @param {MouseEvent} e - The mouse event object.
     */
    const closeOutsideDiv = (e) => {
      if (divRef.current && display && !divRef.current.contains(e.target)) {
        setDisplay(false);
      }
    };
    document.addEventListener("mousedown", closeOutsideDiv);

    return () => document.removeEventListener("mousedown", closeOutsideDiv);
  }, [divRef, display]);

  // Determine which dropdown component to use based on the device type (mobile or desktop).
  let Dropdown = isMobile ? BottomPopup : OptionDivTwo;

  return (
    <PreviousButton
      style={{
        width: "47px",
        marginLeft: "10px",
        height: "47px",
        position: "relative",
        ...style,
      }}
      focus={display}
      ref={divRef}
      onClick={() => setDisplay(!display)}
    >
      <DivText style={styleText}>
        {title ?? <img src={Dots} alt="ellipsis-icon" />}
      </DivText>
      {display && (
        <Dropdown
          containerStyle={{ padding: "24px 16px 48px 16px" }}
          isOpen={display}
          bodyContainerStyle={{ zIndex: isMobile && 9 * 10e9 }}
        >
          {displayContent()}
        </Dropdown>
      )}
    </PreviousButton>
  );
}
