import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        organizations: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        organizationsForUser: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        approvedVideoCount: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        disapprovedVideoCount: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        approvedVideoDateCount: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        disapprovedDateVideoCount: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        newVideoCount: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        brands: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        userOrganizations: {
          merge(existing = [], incoming) {
            return incoming;
          },
          fields: {
            organization: {
              merge(existing = [], incoming) {
                return incoming;
              },
            },
            brands: {
              merge(existing = [], incoming) {
                return incoming;
              },
            },
          },
        },
        videosByBrandId: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        notificationByUserId: {
          merge(existing, incoming) {
            return incoming;
          },

          fields: {
            notificationMessage: {
              merge(existing = [], incoming) {
                return incoming;
              },
            },
          },
        },
      },
    },
  },
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only", // Provides a fast response while also helping to keep cached data consistent with server data.
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all", // useQuery does not discard query response data if even occurrs
  },
  mutate: {
    errorPolicy: "all", // useUsemutation does not discard query response data if even occurrs
  },
};

const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";

// function setCookie(name, value, days) {
//   const date = new Date();
//   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Setting expiration
//   const expires = `expires=${date.toUTCString()}`;
//   document.cookie = `${name}=${value}; ${expires}; path=/`;
// }

// // Middleware to set headers dynamically from Redux
// const authLink = setContext((_, { headers }) => {
//   // Return headers to the context
//   setCookie("punch_id", localStorage.getItem("punchID"), 7);
//   return {
//     headers: {
//       ...headers,
//       // punchID: localStorage.getItem("punchID"),
//     },
//   };
// });

const uploadLink = createUploadLink({
  uri: `${apiUrl}/graphql`,
  credentials: "include",
});

export const client = new ApolloClient({
  link: uploadLink,
  cache,
  defaultOptions,
});
