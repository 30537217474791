import { calculatePxToPercentage } from "./styled";
import React from "react";

const PunchAppIcon = () => {
  return (
    <svg
      width={calculatePxToPercentage(60)}
      height={calculatePxToPercentage(60)}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="white" />
      <rect width="60" height="60" rx="30" fill="#2349B6" />
      <path
        d="M39.4917 25.6682C39.4917 31.7005 35.5175 35.7812 30.2658 35.7812C27.9239 35.7812 26.0077 35.0361 24.6593 33.6522V41.6361H17.5625V16.0875H24.6593V17.6488C26.0077 16.3004 27.9239 15.5198 30.2658 15.5198C35.5175 15.4843 39.4917 19.6004 39.4917 25.6682ZM32.3949 25.6682C32.3949 23.2553 30.8691 21.4101 28.5271 21.4101C26.3626 21.4101 24.6239 23.2198 24.6239 25.6682C24.6239 28.0457 26.3626 29.8554 28.5271 29.8554C30.9046 29.8554 32.3949 28.0457 32.3949 25.6682Z"
        fill="white"
      />
      <circle cx="37.1699" cy="39.209" r="3.26761" fill="white" />
    </svg>
  );
};

export default PunchAppIcon;
